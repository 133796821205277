import React from "react";
import "./Healthform2.css";
// import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import logo from "../resources/Logo.svg";
// import { Accordion } from "react-bootstrap";
import health from "../resources/healthsv.svg";
// import healthques from "../resources/heathquestion.svg";
import female from "../resources/Fem.svg";
import male from "../resources/male.svg";
import daughter from "../resources/Girl.svg";
import { useState } from "react";
import son from "../resources/Boy.svg";
import mother from "../resources/ma.svg";
import father from "../resources/Pa.svg";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";
import { useNavigate } from "react-router-dom";

function Healthform2({familyArr, obj1}) {
  console.log("=============", familyArr);

  const [toogle, settoggle] = useState(false);
  const [selectYes, setSelectYes] = useState("");

  const handleRadioClick = (value) => {
    setSelectYes(value);
  };

  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };
  const handleNextButtonClick = () => {
    const form = document.querySelector("form");
    if (form.checkValidity()) {
      // Proceed to the next page
      navigateTo("/HealthForm1/HealthForm2/ThankYou");
    } else {
      // Show validation errors
      form.reportValidity();
    }
  };

  const [HealthInsuranc2data, setHealthInsuranc2data] = useState({
    Self_Gender:"",
    Self_Person:"",
    Spouse:"",
    Daughter:"",
    Son:"",
    Mother:"",
    Father:"",
    Your_Name: "",
    Mobile_No: "",
    Medical_Condition: "",
  });
  console.log(HealthInsuranc2data);
  const addValues = (e) => {
    setHealthInsuranc2data({
      ...HealthInsuranc2data,
      [e.target.name]: e.target.value,
    });
    console.log(HealthInsuranc2data);
  };
  // console.log(props.familyArr,"-------------------------------")

  const handlesubmit1 = async (e) => {
    e.preventDefault();
    console.log(HealthInsuranc2data);
    try {
      const response = await fetch("https://policydekhoo-backend.onrender.com/Sethealthinsurancedata", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(HealthInsuranc2data),
      });

      if (response) {
        console.log("Data added successfully");
      } else {
        console.error("Failed to add data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [date, setDate] = useState([]);
  // console.log(date);
  const changeDate = (value, index) => {
    console.log(value, index);
    let date1 = [...date];
    date1[index] = value;
    setDate(date1);
    if (index === 0) {
      setHealthInsuranc2data({
        ...HealthInsuranc2data,
        Self_DOB: value,
      });
    } else {
      setHealthInsuranc2data({
        ...HealthInsuranc2data,
        Self_DOB: value,
      });
    }
  };
  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid mb-5 ">
            <div className="row ">
              <div
                className="col-lg-4 mt-3 col-10 ps-5   text-start"
                onClick={() => {
                  navigateTo("/");
                }}
              >
                <img
                  src={logo}
                  alt=""
                  className=""
                  style={{ width: "4vw", cursor: "pointer" }}
                />
                <span
                  className="fs-3 p-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigateTo("/");
                  }}
                >
                  Policy Dekhoo
                </span>
              </div>

              <div className="col-lg-8 col-2 pe-5  d-flex justify-content-end ">
                <div className="row mt-3 ">
                  <div className="col-12  d-none d-lg-block">
                    <ul className="list-inline">
                      <li
                        className="list-inline-item p-2  fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Home
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        About Us
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Support
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Claim
                      </li>
                      <li
                        className="list-inline-item get fs-6 p-2"
                        style={{ cursor: "pointer" }}
                      >
                        Contact Us
                      </li>
                    </ul>
                  </div>
                  <div className="col d-lg-none">
                    <img
                      src={hambergar}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8  text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7 col-1">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li className=" p-2  fs-6" style={{ cursor: "pointer" }}>
                    Home
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    PD Advantages
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Customer Rating
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    About Us
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    Support
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Claim
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2">Contact Us</span>
              </div>
            </div>

            <div className="row fixed-bottom">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid">
            <div className="row ">
              <div className="col-lg-10 ">
                <div className="text-start  mx-5">
                  <span
                    onClick={() => {
                      navigateTo("/");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Home&#62;
                  </span>
                  <span style={{ cursor: "pointer" }}>
                    Health Insurace &#62;
                  </span>
                  <span className="fw-bold">Medical Conditon</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-7 ps-5">
                <div className="">
                  <div className="row">
                    <div className="col-md-6 col-4 ">
                      <hr
                        className="w-100"
                        style={{
                          height: "2px",
                          // width: "10vw",
                          color: "",
                          border: "",
                        }}
                      />
                    </div>
                    <div className="col-md-6 col-4 ">
                      <hr
                        style={{ height: "2px", color: "#2D75E0" }}
                        className="w-100"
                      />
                    </div>
                  </div>
                </div>
                <div className="heade">
                  <div className="w-75 ">
                    <p className="fs-2" style={{ textAlign: "left" }}>
                      Find the Health Insurance Plan That Matches{" "}
                      <span className="fw-bold">your requirements.</span>
                    </p>
                  </div>
                </div>
                <form action="">
                  <div className="row">
                    {familyArr.map((member, index) => (
                      <>
                        <div className="col-6">
                          <img
                            src={
                              member === "Spouse"
                                ? female
                                : member === "Self"
                                ? male
                                : member === "Daughter"
                                ? daughter
                                : member === "Mother"
                                ? mother
                                : member === "Father"
                                ? father
                                : son
                            }
                          />
                          <label className="ps-2">{member}</label>
                          <input
                            type="date"
                            className="p-2 form-control mb-3"
                            name=""
                            value={date[index]}
                            onChange={(e) => {
                              changeDate(e.target.value, index);
                            }}
                          />
                        </div>
                      </>
                    ))}
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="text-start">
                        <label htmlFor="" className="mb-3 fw-bold">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control mb-4"
                          id=""
                          placeholder="Enter Your First Name"
                          name="Your_Name"
                          value={HealthInsuranc2data.Your_Name}
                          onChange={(e) => {
                            addValues(e);
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="text-start">
                        <label htmlFor="" className="mb-3 fw-bold">
                          Mobile No
                        </label>
                        <input
                          type="text"
                          className="form-control mb-4"
                          id=""
                          placeholder="Enter Mobile No"
                          name="Mobile_No"
                          value={HealthInsuranc2data.Mobile_No}
                          onChange={(e) => {
                            addValues(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 mt-4">
                      <label htmlFor="" className="fs-5 fw-bold">
                        Any member with medical condition
                      </label>
                    </div>
                    <div className="col-lg-6 mt-4">
                      <div className="row">
                        <div className="col">
                          <div>
                            <input
                              class="form-check-input  "
                              type="radio"
                              value=""
                              id="flexCheckDefault"
                              checked={selectYes === "Yes"}
                              onClick={() => handleRadioClick("Yes")}
                            />
                            <label
                              class="form-check-label ps-3"
                              for="flexCheckDefault"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div>
                            <input
                              class="form-check-input"
                              type="radio"
                              value=""
                              id="flexCheckDefault"
                              checked={selectYes === "No"}
                              onClick={() => handleRadioClick("No")}
                            />
                            <label
                              class="form-check-label ps-3"
                              for="flexCheckDefault"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <button
                      className="but31 w-25 fs-5 p-3"
                      onClick={(e) => {
                        handlesubmit1(e)
                        handleNextButtonClick()
                      }}
                    >
                      Proceed
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-lg-4 ">
                <div className=" d-none d-lg-block">
                  <img src={health} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row  mx-5">
              <div className="col-lg-12   text-start p-5">
                <h2>Health Insurance Plan</h2>
              </div>
              <div className="row ">
                <div className="col-lg-12 ">
                  <div className="text-start ">
                    <p className="fs-4">
                      Put your health first with Policy Dekhoo's health
                      insurance plans, meticulously designed to cater to your
                      well-being needs. Our comprehensive coverage not only
                      includes hospitalisation expenses but also extends to pre
                      and post-hospitalization costs, ambulance charges, and
                      more. Whether you opt for individual or family floater
                      plans, rest assured of lifelong renewability and access to
                      cashless hospitalisation facilities.
                    </p>
                  </div>

                  <div className="text-start">
                    <p className="fs-4">
                      Benefit from our extensive network of hospitals and
                      personalised assistance, ensuring prompt and effective
                      medical care whenever the need arises. With Policy dekhoo,
                      your health is our priority, offering peace of mind
                      knowing that you're covered with the best possible care.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container-fluid">
            <div className="row px-5 mb-5 ps-3">
              <div className="col-lg-12">
                <h5 style={{ textAlign: "justify" }} className="fs-1 ">
                  FAQ
                </h5>
              </div>
            </div>
            <div className="row px-5 ps-3">
              <div className="col-lg-8 ">
                <div className="row ">
                  <div className="col-sm-12">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Why do I need insurance?
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          How is insurance premium calculated?
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          What is a waiting period in insurance?
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="4">
                        <Accordion.Header>
                          How do I file an insurance claim?
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="5">
                        <Accordion.Header>
                          Can I customize my insurance coverage?
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 ">
                <div>
                  <img src={healthques} alt="" />
                </div>
              </div>
            </div>
          </div> */}

          <footer>
            <div className="container-fluid background-imag1 mt-5 ">
              <div className="row p-5">
                <div className="col-sm-6 order-2 order-sm-1 text-start">
                  <div className="row ps-5">
                    <div className="col-lg-6 ">
                      <ul className="list-unstyled text-start list">
                        <li className="mb-3 fw-bold fs-5">Home </li>
                        <li className="mb-3 fw-bold fs-5">PD Advantages</li>
                        <li className="mb-3 fw-bold fs-5">Customer Rating</li>
                        <li className="mb-3 fw-bold fs-5">About Us</li>
                      </ul>
                    </div>

                    <div className="col-lg-6">
                      <ul className="list-unstyled text-start list">
                        <li className="mb-3 fw-bold fs-5">Support</li>
                        <li className="mb-3 fw-bold fs-5">Claim</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 p-5 text-sm-end text-center order-1 ">
                  <img
                    src={logo}
                    alt=""
                    style={{ width: "5vw" }}
                    className="logo"
                  />
                </div>
              </div>
            </div>
          </footer>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h5 className="fs-6">
                  &#169;2020 Policy Dekhoo | Developed By{" "}
                  <a
                    href="https://www.unairesync.com/"
                    className="link"
                    target="blank"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Unaire Sync
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default Healthform2;
