import React from "react";
import "./Bike3.css";
import { useState, useRef } from "react";
// import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import bikei from "../resources/bikeinsurance.svg";
// import questi from "../resources/ques.svg";
// import Accordion from "react-bootstrap/Accordion";
import logo from "../resources/Logo.svg";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";
import { useNavigate, useParams } from "react-router-dom";

function BikeInsureance3() {
  const [toogle, settoggle] = useState(false);

  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  const contactFormRef = useRef(null);

  // Function to scroll to the contact form
  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  

  const handleNextButtonClick = (e) => {
    e.preventDefault();
    setinvaildemail(false)
    const emailIsValid = mailvalid2(bikeinsurance3.Email_Address);
    if(emailIsValid){

      const form = document.querySelector("form");
  
      if (form.checkValidity()) {
        navigateTo("/BikeInsurance/BikeInsurance2/BikeInsurance3/ThankYou");
      } else {
        form.reportValidity();
        setinvaildemail(true)
  
      }

    }else{
      console.log("Email or name is invalid");
      setinvaildemail(true)
    }
  };


  const mailvalid2=(variablename)=>{
    const checkmail= /^([A-Za-z0-9._]+@[A-Za-z]+\.[A-Za-z]{2,4})$/
    if(variablename.match(checkmail)){
        //email validate true
        console.log("helloooo");
        return(true)
    }
    else{
        //email invalid
        console.log("invald");
        return(false)
    }

  }


  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [errors, setErrors] = useState({});

  // const handleFirstNameChange = (e) => {
  //   const value = e.target.value;
  //   if (/^[A-Za-z ]+$/.test(value) || value === "") {
  //     setFirstName(value);
  //     setErrors((prevErrors) => ({ ...prevErrors, firstName: "" }));
  //   } else {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       // firstName: "Please enter only alphabets",
  //     }));
  //   }
  // };

  // const handleLastNameChange = (e) => {
  //   const value = e.target.value;
  //   if (/^[A-Za-z ]+$/.test(value) || value === "") {
  //     setLastName(value);
  //     setErrors((prevErrors) => ({ ...prevErrors, lastName: "" }));
  //   } else {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       // lastName: "Please enter only alphabets",
  //     }));
  //   }
  // };
  // const [pincode, setPincode] = useState("");
  // console.log(pincode, "-=-=-=-==");
  // const handleNumericOnly = (event) => {
  //   const { value } = event.target;

  //   const isnot = /^[0-9]*$/.test(event.key);
  //   const isnot2 = /^$/.test(event.key);
  //   if (isnot || isnot2) {
  //     setPincode(value);
  //   } else {
  //     event.preventDefault();
  //   }
  // };

  // const handleEmailChange = (e) => {
  //   const value = e.target.value;
  //   setEmail(value);
  //   if (
  //     /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) ||
  //     value === ""
  //   ) {
  //     setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
  //   } else {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       // email: "Please enter a valid email address",
  //     }));
  //   }
  // };

  // const handleContactNumberChange = (e) => {
  //   const value = e.target.value;
  //   if (/^[0-9]*$/.test(value) || value === "") {
  //     setContactNumber(value);
  //     setErrors((prevErrors) => ({ ...prevErrors, contactNumber: "" }));
  //   } else {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       // contactNumber: "Please enter only numerical characters",
  //     }));
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Form submission logic
    // You can use the values of firstName, lastName, email, and contactNumber here
  };

  const [Contactcod, setContactcod] = useState("");
  const [ContactError, setContactError] = useState("");

  const handleContactcod = (event) => {
    const { value } = event.target;
    const isValid = /^[0-9]*$/.test(event.key) || /^$/.test(event.key);

    if (isValid) {
      setContactcod(value);
      setContactError(""); // Reset error message
    } else {
      event.preventDefault();
      setContactError("Contact should contain only numbers.");
    }

    if (value.length !== 10) {
      setContactError("Contact number should be 10 digits long.");
    } else {
      setContactError("");
    }
  };

  const [invaildemail, setinvaildemail] = useState(false)

  const [name, setname] = useState("")
  
  const validateStateVehicleNumber=(VehicleNumber)=>{
    const regex=/^([A-Za-z]{2}[0-9]{2}[A-Za-z]{2}[0-9]{4})$/
    
    if(VehicleNumber.match(regex))
    {
      console.log("valid Number");
      return(true)
    }
    else
    {
      console.log("invalid Number");
      return(false)
    }
  }


  const handleAlphaOnly = (event) => {
    const { value } = event.target;

    const isnot = /^[a-zA-Z]*$/.test(event.key);
    const isnot2 = /^$/.test(event.key);
    if (isnot || isnot2) {
      setname(value);
    } else {
      event.preventDefault();
    }
  };

  const { obj } = useParams();
  const obj1 = JSON.parse(decodeURIComponent(obj));
  console.log(obj1, "------------------------");

  const [bikeinsurance3, setbikeinsurance3] = useState({
    First_Name: "",
    Last_Name: "",
    Email_Address: "",
    Contact_Number: "",
  });

  
  const iddupd = obj1.iddupd;
  const rgno = obj1.rgno;
  const tableName = "bike_insurance";

  const addValues = (e) => {
    if(e.target.name==="Email_Address"){
      setinvaildemail(false)
    }
    setbikeinsurance3({
      ...bikeinsurance3,
      [e.target.name]: e.target.value,
    });
    console.log(bikeinsurance3);
  };

  console.log(bikeinsurance3);

  const handlesubmit1 = async () => {
    console.log("data is:", bikeinsurance3);
    try {
      const response = await fetch(
        `https://policydekhoo-backend.onrender.com/Updatevechiledata?tableName=${tableName}&Registration_No=${rgno}&id=${iddupd}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bikeinsurance3),
        }
      );

      if (response.ok) {
        console.log("Data updated successfully");
      } else {
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid mb-5 ">
            <div className="row ">
              <div
                className="col-lg-4 mt-3 col-10 ps-5   text-start"
                onClick={() => {
                  navigateTo("/");
                }}
              >
                <img
                  src={logo}
                  alt=""
                  className=""
                  style={{ width: "4vw", cursor: "pointer" }}
                />
                <span
                  className="fs-3 p-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigateTo("/");
                  }}
                >
                  Policy Dekhoo
                </span>
              </div>

              <div className="col-lg-8 col-2 pe-5  d-flex justify-content-end ">
                <div className="row mt-3 ">
                  <div className="col-12  d-none d-lg-block">
                    <ul className="list-inline">
                      <li
                        className="list-inline-item p-2  fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigateTo("/");
                        }}
                      >
                        Home
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        About Us
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Support
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Claim
                      </li>
                      <li
                        className="list-inline-item get fs-6 p-2"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Contact Us
                      </li>
                    </ul>
                  </div>
                  <div className="col d-lg-none">
                    <img
                      src={hambergar}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8  text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7 col-1">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li className=" p-2  fs-6" style={{ cursor: "pointer" }}>
                    Home
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    PD Advantages
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Customer Rating
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    About Us
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }} onClick={scrollToContactForm}>
                    Support
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Claim
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2" onClick={scrollToContactForm}>Contact Us</span>
              </div>
            </div>

            <div className="row fixed-bottom">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid">
            <div className="row ">
              <div className="col-md-10 ps-5 ">
                <div className="text-start  mx-5 mt-4">
                  <span
                    onClick={() => {
                      navigateTo("/");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Home&#62;
                  </span>
                  <span className="fw-lighter" style={{ cursor: "pointer" }}>
                    Bike Insurace
                  </span>
                  &#62;
                  <span
                    className="fw-lighter"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigateTo("/BikeInsurance/BikeInsurance2");
                    }}
                  >
                    Previous Insurer
                  </span>
                  &#62;
                  <span className="">Personal Detail</span>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-lg-7  ps-5">
                <div className="row">
                  <div className="row">
                    <div className="col-md-4 col-4">
                      <hr
                        style={{ height: "2px", border: "" }}
                        className="w-100"
                      />
                    </div>
                    <div className="col-sm-4 col-4">
                      <hr style={{ height: "2px" }} className="w-100" />
                    </div>
                    <div className="col-sm-4 col-4">
                      <hr
                        style={{ height: "2px", color: "#2D75E0" }}
                        className="w-100"
                      />
                    </div>
                  </div>
                </div>
                <div className="heade">
                  <div className=" ">
                    <p className="fs-2" style={{ textAlign: "left" }}>
                      Secure Your Bike with Insurance Designed to Meet{" "}
                      <span className="fw-bold">your requirements.</span>
                    </p>
                  </div>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <div className="text-start">
                        <label htmlFor="" className="mb-3 fw-bold">
                          First Name
                        </label>
                        <input
                          type="text"
                          className="form-control p-3"
                          placeholder="Enter Your First Name"
                          name="First_Name"
                          value={bikeinsurance3.First_Name}
                          onChange={(e) => {
                            addValues(e);
                          }}
                          required
                          onKeyPress={handleAlphaOnly}
                        />
                        {errors.firstName && (
                          <div className="text-danger">{errors.firstName}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="text-start">
                        <label htmlFor="" className="mb-3 fw-bold">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="form-control p-3"
                          placeholder="Enter Your Last Name"
                          name="Last_Name"
                          value={bikeinsurance3.Last_Name}
                          onChange={(e) => {
                            addValues(e);
                          }}
                          onKeyPress={handleAlphaOnly}
                          required
                        />
                        {errors.lastName && (
                          <div className="text-danger">{errors.lastName}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-12">
                      <div className="text-start">
                        <label htmlFor="" className="mb-3 fw-bold">
                          Email Address
                        </label>
                        <input
                          type="text"
                          className="form-control p-3"
                          placeholder="Enter Your Email Address"
                          name="Email_Address"
                          value={bikeinsurance3.Email_Address}
                          onChange={(e) => {
                            addValues(e);
                          }}
                          required
                          style={{border:invaildemail===true?"2px solid red":"" }}
                        />
                        {/* {errors.email && (
                          <div className="text-danger">{errors.email}</div>
                        )} */}
                      </div>
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-md-12">
                      <div className="text-start">
                        <label htmlFor="" className="mt-4 mb-3 fw-bold ">
                          Contact Number
                        </label>
                        <div className="input-group flex-nowrap">
                          <span
                            className="input-group-text"
                            id="addon-wrapping"
                          >
                            +91
                          </span>
                          <input
                            type="text"
                            className="form-control p-3"
                            placeholder="Contact Number"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            name="Contact_Number"
                            value={bikeinsurance3.Contact_Number}
                            onChange={(e) => {
                              addValues(e);
                              handleContactcod(e)
                            }}
                            required
                            maxLength={"10"}
                            // onKeyPress={handleContactcod}
                          />
                        </div>
                        {ContactError && (
                          <span style={{ color: "red" }}>{ContactError}</span>
                        )}
                      </div>
                    </div>
                  </div>

                  <button
                    className="but3 w-25 p-2 fs-5"
                    onClick={(e) => {
                      handleNextButtonClick(e)
                      handlesubmit1(e);
                    }}
                  >
                    Check Premium
                  </button>
                </form>
              </div>
              <div className="col-md-5 ps-5 d-none d-lg-block">
                <div className="">
                  <img src={bikei} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row  mx-5">
              <div className="col-md-12   text-start p-3">
                <h2>Bike Insurance Plan</h2>
              </div>
              <div className="row ">
                <div className="col-md-12  p-4">
                  <div className="text-start ">
                    <p className="fs-4">
                      Safeguard your beloved bike with Policy Dekhoo's
                      comprehensive insurance policies tailored to meet your
                      needs. Our coverage encompasses protection against a wide
                      array of risks, including accidents, theft, fire, natural
                      calamities, and third-party liabilities. Moreover, benefit
                      from additional perks such as coverage for accessories and
                      personal accident protection for the owner/driver.
                    </p>
                  </div>

                  <div className="text-start ">
                    <p className="fs-4">
                      Rest assured, with Policy Dekhoo's extensive network and
                      round-the-clock customer support, you'll experience a
                      worry-free riding journey. Whether it's navigating
                      bustling city streets or exploring scenic routes, ride
                      with confidence knowing that Policy Dekhoo has you covered
                      every step of the way.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container-fluid">
        <div className="row px-5 mb-5 ">
          <div className="col-md-12">
            <h5 style={{ textAlign: "justify" }} className="fs-1 ">
              FAQ
            </h5>
          </div>
        </div>
        <div className="row px-5 ">
          <div className="col-lg-8 ">
            <div className="row ">
              <div className="col-md-12">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Why do I need insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How is insurance premium calculated?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What is a waiting period in insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How do I file an insurance claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I customize my insurance coverage?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-none d-lg-block ">
            <div>
              <img src={questi} alt="" />
            </div>
          </div>
        </div>
      </div> */}

          <footer>
            <div className="container-fluid background-imag1 mt-5 ">
              <div className="row p-5">
                <div className="col-sm-6 order-2 order-sm-1 text-start">
                  <div className="row ps-5">
                    <div className="col-lg-6 ">
                      <ul className="list-unstyled text-start list">
                        <li
                          className="mb-3 fw-bold fs-5"
                          onClick={() => {
                            navigateTo("/");
                          }}
                        >
                          Home{" "}
                        </li>
                        <li className="mb-3 fw-bold fs-5">PD Advantages</li>
                        <li className="mb-3 fw-bold fs-5">Customer Rating</li>
                        <li className="mb-3 fw-bold fs-5">About Us</li>
                      </ul>
                    </div>

                    <div className="col-lg-6">
                      <ul className="list-unstyled text-start list">
                        <li className="mb-3 fw-bold fs-5">Support</li>
                        <li className="mb-3 fw-bold fs-5">Claim</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 p-5 text-sm-end text-center order-1 ">
                  <img
                    src={logo}
                    alt=""
                    style={{ width: "5vw" }}
                    className="logo"
                  />
                </div>
              </div>
            </div>
          </footer>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h5 className="fs-5">
                  &#169;2020 Policy Dekhoo | Developed By{" "}
                  <a
                    href="https://www.unairesync.com/"
                    className="link"
                    target="blank"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Unaire Sync
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default BikeInsureance3;
