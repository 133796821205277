import logo from './logo.svg';
import './App.css';
import Landingpage from './Landingpage/Landingpage';
import CarForm from './CarInsurance/CarForm';
import CarForm2 from './CarInsurance/CarForm2';
import CarForm3 from './CarInsurance/CarForm3';
// import Sample from './Sample';
import BikeInsuresnce from './BikeIndurance/BikeInsuresnce';
import BikeInsureance2 from './BikeIndurance/BikeInsureance2';
import BikeInsureance3 from './BikeIndurance/BikeInsureance3';
import LifeInsurance from "./LifeInsurance/LifeInsuranceform"
import Travel from './Travel/TravelForm';
import GroupInsuranceform from './GroupInsurance/GroupInsuranceform';
import PersonalForm from "./PersonalAccident/Personalloneform.jsx"
import PersonalForm2 from './PersonalAccident/PersonalForm2.jsx';
import HealthForm1 from './Healthinsurance/HealthForm1';
import RoutingFile from './RoutingFile';
import Healthform2   from './Healthinsurance/Healthform2';
import Option from "./Navbar/Nav.jsx"
import CreditCardForm1 from './CredtCard/CreditCardForm1.jsx';
import CreditCardForm2 from './CredtCard/CreditCardForm2.jsx';
import CreditCardForm3 from './CredtCard/CreditCardForm3.jsx';
import PageNotFound from './PageNotFound/PageNotFound.jsx';
import ThankYou from './Thank You/ThankYou.jsx';
import LoanForm2 from './PersonalLoan/LoanForm2.jsx'
import LoanForm1 from './PersonalLoan/LoanForm.jsx';
import LoanForm3 from './PersonalLoan/LoanForm3.jsx'
import AboutUs from './Aboutus/About.jsx';

function App() {
  return (
    <div className="App">
      <RoutingFile/>
      {/* <AboutUs/> */}
      {/* <CarInsurance/> */}
      {/* <Login/> */}
      {/* <Landingpage/> */}
      {/* <Option/> */}
      {/* <CarForm/>  */}
      {/* <CarForm2/> */}
      {/* <CarForm3/> */}
      {/* <Sample/> */}
      {/* <BikeInsuresnce/> */}
      {/* <BikeInsureance2/> */}
      {/* <BikeInsureance3/> */}
      {/* <LifeInsurance/> */}
      {/* <Travel/> */}
      {/* <GroupInsuranceform/> */}
      {/* <PersonalForm /> */}
      {/* <PersonalForm2/> */}
      {/* <HealthForm1/>   */}
      {/* <Healthform2/> */}
      {/* <CreditCardForm1/> */}
      {/* <CreditCardForm2/> */}
      {/* <CreditCardForm3/> */}
      {/* <PageNotFound/> */}
      {/* <ThankYou/> */}
      {/* <LoanForm1/> */}
      {/* <LoanForm2/> */}
      {/* <LoanForm3/> */}
    </div>
  );
}

export default App;
