import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useRef } from "react";
import log from "../resources/Logo.svg";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";
import card from "../resources/ccard.svg";
import "./CreditCardForm3.css";

function CreditCardForm3() {
  const [toogle, settoggle] = useState(false);

  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  const contactFormRef = useRef(null);

  // Function to scroll to the contact form
  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [selectYes, setSelectYes] = useState("Null");

  const handleRadioClick = (value) => {
    setSelectYes(value);
    if (value === "Yes") {
      setCreditCard3({
        ...CreditCard3,
        Have_Any_Credit_Card: value,
      });
    } else {
      setCreditCard3({
        ...CreditCard3,
        Have_Any_Credit_Card: value,
        Bank: null,
        Card_Limit: null,
      });
    }
  };

  const handleNextButtonClick = () => {
    const form = document.querySelector("form");
    if (form.checkValidity()) {
      // Proceed to the next page
      navigateTo("/CreditCardForm1/CreditCardForm2/CreditCardForm3/ThankYou");
    } else {
      // Show validation errors
      form.reportValidity();
    }
  };
  const [pincode, setPincode] = useState("");
  console.log(pincode, "-=-=-=-==");
  const handleNumericOnly = (event) => {
    const { value } = event.target;

    const isnot = /^[0-9]*$/.test(event.key);
    const isnot2 = /^$/.test(event.key);
    if (isnot || isnot2) {
      setPincode(value);
    } else {
      event.preventDefault();
    }
  };

  const { obj } = useParams();
  const obj1 = JSON.parse(decodeURIComponent(obj));

  const [CreditCard3, setCreditCard3] = useState({
    Have_Any_Credit_Card: "",
    Bank: "",
    Card_Limit: "",
  });

  const VehicleInformation = () => {
    const serializedObj = encodeURIComponent(JSON.stringify(CreditCard3));

    // Proceed to the next page
    navigateTo(`/CreditCardForm1/CreditCardForm2/${serializedObj}`);
  };
  const iddupd = obj1.iddupd;
  const tableName = "credit_card_insurance";

  const addValues = (e) => {
    setCreditCard3({ ...CreditCard3, [e.target.name]: e.target.value });
  };
  console.log(CreditCard3);

  const handlesubmit2 = async () => {
    console.log("data is:", CreditCard3);
    try {
      const response = await fetch(
        `https://policydekhoo-backend.onrender.com/Updatedata?tableName=${tableName}&id=${iddupd}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(CreditCard3),
        }
      );

      if (response.ok) {
        console.log("Data updated successfully");
      } else {
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid mb-5 ">
            <div className="row ">
              <div
                className="col-lg-4 align-items-center d-flex mt-3  col-10 ps-5   text-start"
                onClick={() => {
                  navigateTo("/");
                }}
              >
                <div>
                  <img
                    src={log}
                    alt=""
                    className=""
                    style={{ width: "4vw", cursor: "pointer" }}
                  />
                </div>
                <div
                  className="fs-3 "
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigateTo("/");
                  }}
                >
                  Policy Dekhoo
                </div>
              </div>

              <div className="col-lg-8 col-2 pe-5  d-flex justify-content-end ">
                <div className="row mt-3 ">
                  <div className="col-12  d-none d-lg-block">
                    <ul className="list-inline">
                      <li
                        className="list-inline-item p-2  fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigateTo("/");
                        }}
                      >
                        Home
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        About Us
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Support
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Claim
                      </li>
                      <li
                        className="list-inline-item get fs-6 p-2"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Contact Us
                      </li>
                    </ul>
                  </div>
                  <div className="col d-lg-none">
                    <img
                      src={hambergar}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8  text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7 col-1">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li
                    className=" p-2  fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigateTo("/");
                    }}
                  >
                    Home
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    PD Advantages
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Customer Rating
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    About Us
                  </li>
                  <li
                    className=" p-2 fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={scrollToContactForm}
                  >
                    Support
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Claim
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2" onClick={scrollToContactForm}>
                  Contact Us
                </span>
              </div>
            </div>

            <div className="row fixed-bottom">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid ">
            <div className="row text-start p-3  ">
              <div className="col ps-lg-5" style={{ cursor: "pointer" }}>
                <span
                  className=""
                  onClick={() => {
                    navigateTo("/");
                  }}
                >
                  Home&#62;
                </span>
                <span
                  className=""
                  onClick={() => {
                    navigateTo("/CreditCardForm1");
                  }}
                >
                  Credit Card&#62;
                </span>
                <span
                  onClick={() => {
                    VehicleInformation();
                  }}
                >
                  Vehicle Infromation &#62;
                </span>
                <span style={{ fontWeight: "500" }}>Card Limit</span>
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-7   p-3 ">
                <div className="row ps-lg-5">
                  <div className="col-md-4 col-4 ">
                    <hr
                      className="w-100"
                      style={{
                        height: "2px",
                        // width: "10vw",
                        color: "#2D75E0",
                        border: "",
                      }}
                    />
                  </div>
                  <div className="col-md-4 col-4 ">
                    <hr style={{ height: "2px" }} className="w-100" />
                  </div>
                  <div className="col-md-4 col-4">
                    <hr style={{ height: "2px" }} className="w-100" />
                  </div>
                </div>
                <div className="">
                  <p
                    className="fs-3 px-2 ps-lg-5"
                    style={{ textAlign: "left" }}
                  >
                    Secure Your Finances with Credit Card Tailored to
                    <span className="fw-bold"> your requirements.</span>
                  </p>
                </div>

                <div className="container">
                  <form action="">
                    <div
                      className="row mt-5 mb-3 ps-md-5 needs-validation"
                      novalidate
                    >
                      <div className=" col-lg-6  text-start">
                        <div>
                          <label htmlFor="" className="mb-3 fw-bold">
                            Do You Have Any Credit Card
                          </label>
                        </div>
                      </div>

                      <div className=" col-lg-6   text-start">
                        <div>
                          <input
                            type="radio"
                            id=""
                            name=""
                            value=""
                            className=""
                            required
                            checked={selectYes === "Yes"}
                            onClick={() => handleRadioClick("Yes")}
                          />
                          <label for="" className="me-5 ps-3">
                            Yes
                          </label>

                          <input
                            type="radio"
                            id=""
                            name=""
                            value=""
                            checked={selectYes === "No"}
                            onClick={() => handleRadioClick("No")}
                            required
                          />
                          <label for="" className="ps-3">
                            No
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-5 mt-4 ps-md-5">
                      <div className="col-md-12  ms-0 text-start">
                        <div>
                          <label htmlFor="" className="mb-3 fw-bold">
                            Bank
                          </label>
                        </div>
                        <div>
                          <select id="" className="form-select p-3"

                            name="Bank"
                            value={CreditCard3.Bank}
                            onChange={(e) => {
                              addValues(e);
                            }}
                            disabled={selectYes !== "Yes"} >
                            <option value="">Select Bank</option>
                            <option value="SBI Card">SBI Card</option>
                            <option value="American Express">
                              American Express
                            </option>
                            <option value="Axis Ace Credit Card">
                              Axis Ace Credit Card
                            </option>
                            <option value="HDFC Credit Card eligibility">
                              HDFC Credit Card eligibility
                            </option>
                            <option value="Amazon Pay ICICI Credit card">
                              Amazon Pay ICICI Credit card
                            </option>
                            <option value="IDFC Bank">IDFC Bank</option>
                            <option value="Standard Chartered credit card Eligibility">
                              Standard Chartered credit card Eligibility
                            </option>
                            <option value="Yes Bank">Yes Bank</option>
                            <option value="Citi">Citi</option>
                            <option value="HDFC Bank">HDFC Bank</option>
                            <option value="IndusInd Bank">IndusInd Bank</option>
                            <option value="Kotak Mahindra Bank">
                              Kotak Mahindra Bank
                            </option>
                            <option value="BPCL SBI Card Octane">
                              BPCL SBI Card Octane
                            </option>
                            <option value="HSBC credit card eligibility">
                              HSBC credit card eligi+
                              bility
                            </option>
                            <option value="ICICI Bank">ICICI Bank</option>
                            <option value="Axis Bank">Axis Bank</option>
                            <option value="IDBI Bank">IDBI Bank</option>
                            <option value="MakeMyTrip Signature Credit Card">
                              MakeMyTrip Signature Credit Card
                            </option>
                            <option value="Standard Chartered Bank">
                              Standard Chartered Bank
                            </option>
                            <option value="RBL Bank">RBL Bank</option>
                            <option value="RBL Bank credit Card eligibility">
                              RBL Bank credit Card eligibility
                            </option>
                            <option value="Canara Bank Credit Card eligibility">
                              Canara Bank Credit Card eligibility
                            </option>
                          </select>
                          {/* <input
                            type="text"
                            className="form-control p-3"
                            placeholder="Select Higher Limit Credit Card"
                            required
                            disabled={selectYes === "No"}
                            maxLength={"9"}
                            name="Higher_Limit"
                            value={CreditCard3.Higher_Limit}
                            onChange={(e) => {
                              addValues(e);
                            }}
                            onKeyPress={handleNumericOnly}
                          /> */}
                        </div>
                      </div>
                    </div>

                    <div className="row ps-md-5">
                      <div className="col-md-12 ">
                        <div className="text-start">
                          <label htmlFor="" className="fw-bold mb-3">
                            Card Limit
                          </label>
                          <input
                            type="text"
                            className="form-control p-3"
                            name="Card_Limit"
                            value={CreditCard3.Card_Limit}
                            onChange={(e) => {
                              addValues(e);
                            }}
                            placeholder="Enter Credit Card Limit"
                            required
                            disabled={selectYes !== "Yes"}
                            maxLength={"9"}
                            onKeyPress={handleNumericOnly}
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      className="p-2 mt-5 but24 w-25 fs-5"
                      onClick={() => {
                        handlesubmit2();
                        handleNextButtonClick()
                      }}
                    >
                      Check Offer
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-lg-4  d-none d-lg-block">
                <div className="position-relative mt-5 py-5">
                  <img src={card} alt="" className="" />
                </div>
              </div>
            </div>
          </div>

          {/* HPI */}
          <div className="container-fluid">
            <div className="row  mx-5">
              <div className="col-md-12   text-start p-3 mt-5">
                <h2>Credit Card Plan</h2>
              </div>
              <div className="row ">
                <div className="col-lg-12 ">
                  <div className="text-start ">
                    <p className="fs-4">
                      Welcome to our Credit Card section! Discover a world of
                      financial freedom and convenience with our range of credit
                      card offerings. Whether you're looking for travel rewards,
                      cash back incentives, or low APR rates, we have the
                      perfect card to suit your lifestyle and financial goals.
                      With easy online application processes and competitive
                      benefits, managing your expenses has never been more
                      rewarding.
                    </p>
                  </div>

                  <div className="text-start">
                    <p className="fs-4">
                      Enjoy perks such as exclusive discounts, fraud protection,
                      and 24/7 customer support. Explore our diverse selection
                      of credit cards today and take the first step towards
                      smarter spending and greater financial flexibility.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 
      <div className="container-fluid">
        <div className="row px-5 mb-5">
          <div className="col-md-12">
            <h5 style={{ textAlign: "justify" }} className="fs-1 ">
              FAQ
            </h5>
          </div>
        </div> */}
          {/* <div className="row px-5 ">
          <div className="col-lg-8 ">
            <div className="row ">
              <div className="col-md-12">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Why do I need insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How is insurance premium calculated?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What is a waiting period in insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How do I file an insurance claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I customize my insurance coverage?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>

          <div className="col-4  d-none d-lg-block">
            <div>
              <img src={question} alt="" />
            </div>
          </div>
        </div>
      </div> */}

          {/* Footer */}

          <footer>
            <div className="container-fluid background-imag1 mt-5 ">
              <div className="row p-5">
                <div className="col-sm-6 order-2 order-sm-1 text-start">
                  <div className="row ps-5">
                    <div className="col-lg-6 ">
                      <ul className="list-unstyled text-start list">
                        <li
                          className="mb-3 fw-bold fs-5"
                          onClick={() => {
                            navigateTo("/");
                          }}
                        >
                          Home{" "}
                        </li>
                        <li className="mb-3 fw-bold fs-5">PD Advantages</li>
                        <li className="mb-3 fw-bold fs-5">Customer Rating</li>
                        <li className="mb-3 fw-bold fs-5">About Us</li>
                      </ul>
                    </div>

                    <div className="col-lg-6">
                      <ul className="list-unstyled text-start list">
                        <li className="mb-3 fw-bold fs-5">Support</li>
                        <li className="mb-3 fw-bold fs-5">Claim</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 p-5 text-sm-end text-center order-1 ">
                  <img
                    src={log}
                    alt=""
                    style={{ width: "5vw" }}
                    className="logo"
                  />
                </div>
              </div>
            </div>
          </footer>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h5 className="fs-6">
                  &#169;2020 Policy Dekhoo | Developed By{" "}
                  <a
                    href="https://www.unairesync.com/"
                    className="link"
                    target="blank"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Unaire Sync
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default CreditCardForm3;
