import React from "react";
import { useState, useRef } from "react";
import "./Abut.css";
import { useNavigate } from "react-router-dom";
import log from "../resources/Logo.svg";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";

function AboutUs() {
  const [toogle, settoggle] = useState(false);

  const contactFormRef = useRef(null);

  // Function to scroll to the contact form
  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };
  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid mb-5 ">
            <div className="row">
              <div className="col-lg-4 mt-3 col-10 ps-5   text-start" style={{cursor:"pointer"}} onClick={() => {
                    navigateTo("/");
                  }}>
                <img
                  src={log}
                  alt=""
                  className=""
                  style={{ width: "4vw" }}
                />
                <span className="fs-3 p-2">Policy Dekhoo</span>
              </div>

              <div className="col-lg-8 col-2 pe-5  d-flex justify-content-end ">
                <div className="row mt-3 ">
                  <div className="col-12  d-none d-lg-block">
                    <ul className="list-inline">
                      <li
                        className="list-inline-item p-2  fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigateTo("/")
                        }}
                      >
                        Home
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        About Us
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Support
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Claim
                      </li>
                      <li
                        className="list-inline-item get fs-6 p-2"
                        style={{ cursor: "pointer" }}

                        onClick={scrollToContactForm}
                      >
                        Contact Us
                      </li>
                    </ul>
                  </div>
                  <div className="col d-lg-none">
                    <img
                      src={hambergar}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8  text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7 col-1">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li className=" p-2  fs-6" style={{ cursor: "pointer" }}>
                    Home
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    PD Advantages
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Customer Rating
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    About Us
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }} onClick={scrollToContactForm}>
                    Support
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Claim
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2" onClick={scrollToContactForm}>Contact Us</span>
              </div>
            </div>

            <div className="row fixed-bottom">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-start ps-5">
                  <span onClick={() => {
                    navigateTo("/")
                  }} style={{ cursor: "pointer" }}>Home&#62;</span>
                  <span>About Us</span>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-start ps-5 mt-5">
                  <h1>About Us</h1>
                </div>
                <div className=" mt-5 ps-5 w-100" style={{textAlign:"left"}}>
                  <p className="fs-5">
                    Policy Dekhoo is not just another insurance platform; it's your trusted partner in navigating the often complex world of insurance with ease and confidence. We understand that choosing the right insurance coverage can be daunting, which is why we've made it our mission to simplify the process and provide you with transparent options tailored specifically to your unique needs.
                  </p>
                </div>

                <div className="mt-4 ps-5" style={{textAlign:"left"}}>
                  <p className="fs-5">
                    At Policy Dekhoo, we believe in empowerment through information. Our user-friendly platform is designed to put you in the driver's seat, allowing you to compare policies, understand coverage details, and make informed decisions with ease. Gone are the days of sifting through confusing jargon or feeling overwhelmed by endless options. With Policy Dekhoo, you'll have everything you need to make the best choice for yourself and your loved ones, right at your fingertips.                    </p>
                </div>

                <div className="mt-4 ps-5" style={{textAlign:"left"}}>
                  <p className="fs-5">
                    But we're more than just a website—we're a team of dedicated professionals committed to providing you with personalized guidance and exceptional service every step of the way. Whether you have questions about a specific policy, need help navigating the claims process, or simply want expert advice on how to best protect your assets, our knowledgeable team is here to assist you. We believe in building lasting relationships with our customers, and we'll go above and beyond to ensure that you have the protection you need, when you need it most.                  </p>
                </div>

                <div className="mt-4 ps-5" style={{textAlign:"left"}}>
                  <p className="fs-5">
                    From auto and home insurance to health and life coverage, Policy Dekhoo has you covered across all aspects of your life. Our comprehensive range of products and services ensures that you can find the perfect fit for your individual circumstances, helping you safeguard your assets and achieve true peace of mind.
                  </p>
                </div>

                <div className="mt-4 ps-5" style={{textAlign:"left"}}>
                  <p className="fs-5">
                    So why wait? Join us on the journey to a secure future today. Discover why thousands of customers trust Policy Dekhoo for their insurance needs and experience firsthand the simplicity, transparency, and stress-free shopping experience that we're known for. Welcome to Policy Dekhoo—where insurance shopping is not just simple, but empowering.                  </p>
                </div>
              </div>
            </div>
          </div>



          <footer>
            <div className="container-fluid background-imag1 mt-5 ">
              <div className="row p-5">
                <div className="col-sm-6 order-2 order-sm-1 text-start">
                  <div className="row ps-5">
                    <div className="col-lg-6 ">
                      <ul className="list-unstyled text-start list">
                        <li
                          className="mb-3 fw-bold fs-5"
                          onClick={() => {
                            navigateTo("/");
                          }}
                        >
                          Home{" "}
                        </li>
                        <li className="mb-3 fw-bold fs-5">PD Advantages</li>
                        <li className="mb-3 fw-bold fs-5">Customer Rating</li>
                        <li className="mb-3 fw-bold fs-5">About Us</li>
                      </ul>
                    </div>

                    <div className="col-lg-6">
                      <ul className="list-unstyled text-start list">
                        <li className="mb-3 fw-bold fs-5">Support</li>
                        <li className="mb-3 fw-bold fs-5">Claim</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 p-5 text-sm-end text-center order-1 ">
                  <img
                    src={log}
                    alt=""
                    style={{ width: "5vw" }}
                    className="logo"
                  />
                </div>
              </div>
            </div>
          </footer>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h5 className="fs-5">
                  &#169;2020 Policy Dekhoo | Developed By{" "}
                  <a
                    href="https://www.unairesync.com/"
                    className="link"
                    target="blank"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Unaire Sync
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default AboutUs;
