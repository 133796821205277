import React from "react";
import "./BikeInsureance.css";
import { useState, useEffect, useRef } from "react";
// import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import bikein from "../resources/bikeinsurance.svg";
// import questi from "../resources/ques.svg";
// import Accordion from "react-bootstrap/Accordion";
import brand from "../resources/Logo.svg";
import { useNavigate } from "react-router-dom";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";

function BikeInsuresnce() {
  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  const contactFormRef = useRef(null);

  // Function to scroll to the contact form
  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [toogle, settoggle] = useState(false);

  const [selectYes, setselectYes] = useState(null);

  const handleRadioClick = (value) => {
    if (value === selectYes) {
      setselectYes(null);
      setbike_insurance({ ...bike_insurance, Previous_Year_Policy: value });
    } else {
      setselectYes(value);
      setbike_insurance({ ...bike_insurance, Previous_Year_Policy: value });
    }
  };

  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [models, setModels] = useState([]);

  const handleManufacturerChange = (event) => {
    const manufacturer = event.target.value;
    setSelectedManufacturer(manufacturer);

    // Filter models based on selected manufacturer
    const filteredModels = getModelsForManufacturer(manufacturer);
    setModels(filteredModels);
    setbike_insurance({ ...bike_insurance, Manufacturer: event.target.value });
  };

  const getModelsForManufacturer = (manufacturer) => {
    const manufacturerModels = {
      YAMAHA: [
        "Yamaha MT-10",
        "Yamaha MT-09",
        "Yamaha MT-07",
        "Yamaha MT-03",
        "Yamaha MT-15",
        "Yamaha MT-25",
        "Yamaha YZF-R1M",
        "Yamaha YZF-R1M",
        "Yamaha YZF-R1",
        "Yamaha YZF-R6",
        "Yamaha YZF-R3",
        "Yamaha Tracer 900 GT",
        "Yamaha Tracer 700",
        "Yamaha XSR900",
        "Yamaha XSR700",
        "Yamaha FJR1300",
        "Yamaha Super Ténéré",
        "Yamaha Niken",
        "Yamaha Tenere 700",
        "Yamaha XTZ125",
        "Yamaha YBR125",
        "Yamaha FZ1",
        "Yamaha FZ6",
        "Yamaha FZ8",
        "Yamaha WR250R",
        "Yamaha WR250F",
        "Yamaha WR450F",
        "Yamaha TT-R125",
        "Yamaha TT-R230",
        "Yamaha XT250",
        "Yamaha XT660Z Ténéré",
        "Yamaha PW50",
        "Yamaha TW200",
      ],
      BAJAJ: [
        "Bajaj Pulsar NS200",
        "Bajaj Pulsar 125",
        "Bajaj Pulsar NS 125",
        "Bajaj Pulsar 150",
        "Bajaj Pulsar RS200",
        "Bajaj Pulsar N160",
        "Bajaj Pulsar 220 F",
        "Bajaj Pulsar NS160",
        "Bajaj Dominar 400",
        "Bajaj Chetak",
        "Bajaj Chetak",
        "Bajaj Pulsar N150",
        "Bajaj Platina 110",
        "Bajaj Platina 100",
        "Bajaj Dominar 250",
        "Bajaj Avenger Street 160",
        "Bajaj CT 110X",
        "Bajaj Pulsar F250",
        "Bajaj Avenger Cruise 220",
      ],
      HEROELECTRIC: [
        "CRUZ",
        "DASH",
        "E SPRINT",
        "FLASH",
        "LITHIUM-ION",
        "MAXI",
        "NYX",
        "OPTIMA",
        "PHOTON",
        "SUPER SPLENDOR",
        "WAVE",
        "ZION",
        "ZIPPY",
      ],
      HEROHONDA: [
        "HUNK",
        "AMBITION",
        "JOY",
        "KARIZMA",
        "PASSION",
        "PASSION PLUS",
        "PASSION PRO",
        "CD 100",
        "CD DAWN",
        "CD DELUXE",
        "PLEASURE",
        "GLAMOUR",
        "SPLENDOR",
        "SPLENDOR +",
        "SPLENDOR ISMART 110",
        "SPLENDOR NXG",
      ],
      HEROMOTOCORP: [
        "CD DAWN",
        "CD DELUXE",
        "HF DAWN",
        "HF DELUXE",
        "HF DELUXE ECO",
        "DESTINI",
        "DUET",
        "IGNITOR",
        "HUNK",
        "XPULSE",
        "GLAMOUR",
        "MAESTRO",
        "MAESTRO EDGE",
        "PASSION",
        "PASSION PRO",
        "PASSION X PRO",
        "PASSION XPRO",
        "PLEASURE",
        "PLEASURE PLUS",
        "SPLENDOR",
        "SPLENDOR I SMART",
        "SPLENDOR ISMART 110",
        "SPLENDOR NXG",
        "SPLENDOR PLUS",
        "SPLENDOR PRO",
        "XTREME",
        "XTREME SPORTS",
        "Bajaj Pulsar P150",
        "Bajaj Avenger 220 Street",
        "",
      ],
      KAWASAKI: [
        "Kawasaki Z900",
        "Kawasaki Z900",
        "Kawasaki Ninja ZX-6R",
        "Kawasaki Ninja H2R",
        "Kawasaki Ninja H2R",
        "Kawasaki Ninja 300",
        "Kawasaki Ninja 650",
        "Kawasaki Ninja 500",
        "Kawasaki Z650",
        "Kawasaki Ninja 400",
        "Kawasaki Ninja ZX-4R",
        "Kawasaki Versys 650",
        "Kawasaki W175",
        "Kawasaki Eliminator",
        "Kawasaki Vulcan S",
        "Kawasaki Ninja H2 SX",
        "Kawasaki Z650RS",
        "Kawasaki Z900RS",
        "Kawasaki Ninja H2 SX SE",
      ],
      KTM: [
        "KTM Duke 390",
        "KTM Duke 200",
        "KTM 125 Duke",
        "KTM 250 Duke",
        "KTM RC 200",
        "KTM RC 390",
        "KTM RC 125",
        "KTM 390 Adventure",
        "KTM 250 Adventure",
        "KTM 390 Adventure X",
      ],
      ROYALENFIELD: [
        "Royal Enfield Classic 350",
        "Royal Enfield Hunter 350",
        "Royal Enfield Bullet 350",
        "Royal Enfield Continental GT 650",
        "Royal Enfield Himalayan 450",
        "Royal Enfield Meteor 350",
        "Royal Enfield Interceptor 650",
        "Royal Enfield Super Meteor 650",
        "Royal Enfield Shotgun 650",
        "Royal Enfield Scram 411",
      ],
      SUZUKI: [
        "Suzuki Access 125",
        "Suzuki Hayabusa",
        "Suzuki Hayabusa",
        "Suzuki Burgman Street",
        "Suzuki Gixxer SF",
        "Suzuki Gixxer",
        "Suzuki Gixxer SF 250",
        "Suzuki V-Strom SX",
        " Suzuki Avenis",
        "Suzuki Avenis",
        "Suzuki Katana",
      ],
      TRIUMPH: [
        "Triumph Speed 400",
        "Triumph Scrambler 400 X",
        "Triumph Rocket 3",
        "Triumph Street Triple",
        "Triumph Trident 660",
        "Triumph Bonneville Bobber",
        "Triumph Tiger 900",
        "Triumph Tiger Sport 660",
        "Triumph Speed Twin 900",
        "Triumph Scrambler 900",
        "Triumph Tiger 1200",
        "Triumph Bonneville Speedmaster",
        "Triumph Speed Twin",
        "Triumph Bonneville T120",
        "Triumph Bonneville T100",
        "Triumph Tiger 850 Sport",
        "Triumph Scrambler 1200 X",
        "Triumph Speed Triple 1200",
      ],
      TVS: [
        "TVS Apache RTR 160",
        "TVS Raider",
        "TVS Apache RTR 160 4V",
        "TVS NTORQ 125",
        "TVS NTORQ 125",
        "TVS Jupiter",
        "TVS Apache RTR 310",
        "TVS XL100",
        "TVS Jupiter 125",
        "TVS Ronin",
        "TVS Apache RR 310",
        "TVS Scooty Pep Plus",
        "TVS Sport",
        "TVS Apache RTR 180",
        "TVS Apache RTR 200 4V",
        "TVS Radeon",
        "TVS Scooty Zest",
        "TVS Star City Plus",
        "TVS Star City Plus",
        "TVS X",
      ],
      JAWA: ["Jawa 42 Bobber", "Jawa 42", "Jawa Perak", "Jawa 350"],
      HONDA: [
        "Honda Activa 6G",
        "Honda SP 125",
        "Honda Shine",
        "Honda Activa 125",
        "Honda Dio",
        "Honda Unicorn",
        "Honda Hness CB350",
        "Honda Hornet 2.0",
        "Honda SP160",
        "Honda Shine 100",
        "Honda CB200X",
        "Honda Dio 125",
        "Honda CB350",
        "Honda Livo",
        "Honda CB300F",
        "Honda CB300R",
        "Honda CB350RS",
        "Honda CD 110 Dream",
        "Honda Gold Wing",
        "Honda NX500",
        "Honda CRF1100L Africa Twin",
        "Honda XL750 Transalp",
      ],
    };

    return manufacturerModels[manufacturer] || [];
  };

  const [InvalidRegiter, setInvalidRegiter] = useState(false);

  const validateStateVehicleNumber = (VehicleNumber) => {
    const regex = /^([A-Za-z]{2}[0-9]{2}[A-Za-z]{2}[0-9]{4})$/;

    if (VehicleNumber.match(regex)) {
      console.log("valid Number");
      return true;
    } else {
      console.log("invalid Number");
      return false;
    }
  };

  const handleNextButtonClick = (e) => {
    console.log(selectYes);

    const form = document.querySelector("form");

    const a = validateStateVehicleNumber(bike_insurance.Registration_No);
    console.log(a, "");
    if (a === false) {
      setInvalidRegiter(true);
    } else {
      if (form.checkValidity()) {
        if (selectYes === "Yes") {
          console.log("if");
          const serializedObj = encodeURIComponent(
            JSON.stringify(bike_insurance)
          );
          navigateTo(`/BikeInsurance/BikeInsurance2/${serializedObj}`);
        } else {
          console.log("else");
          const serializedObj = encodeURIComponent(
            JSON.stringify(bike_insurance)
          );
          navigateTo(
            `/BikeInsurance/BikeInsurance2/BikeInsurance3/${serializedObj}`
          );
        }
      }
    }
  };

  const tableName = "bike_insurance";
  const [newidd, setnewidd] = useState();

  useEffect(() => {
    const getnewid = async () => {
      const url = `https://policydekhoo-backend.onrender.com/Getnewid?tableName=${tableName}`;

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch groups");
        }

        const data = await response.json();
        console.log("new id:", data);
        setnewidd(data);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    getnewid();
  }, []);

  const [bike_insurance, setbike_insurance] = useState({
    id: "",
    Registration_No: "",
    Manufacturer: "",
    Model: "",
    Previous_Year_Policy: "",
  });
  useEffect(() => {
    setbike_insurance((prevBikeInsurance) => ({
      ...prevBikeInsurance,
      id: newidd, // Update id with newidd
    }));
  }, [newidd]);

  useEffect(() => {
    console.log("New ID22:", newidd);
    console.log("New ID22=========:", bike_insurance.id);
  }, [newidd, bike_insurance.id]);

  const addValues = (e) => {
    if (e.target.name === "Registration_No") {
      setInvalidRegiter(false);
    }
    setbike_insurance({ ...bike_insurance, [e.target.name]: e.target.value });
  };
  console.log(bike_insurance);

  const handlesubmit1 = async (e) => {
    e.preventDefault();

    console.log(bike_insurance);
    try {
      const response = await fetch(
        "https://policydekhoo-backend.onrender.com/Setbikeinsurancedata",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bike_insurance),
        }
      );

      if (response) {
        const responseData = await response.json();
        console.log("Data added successfully");
        console.log("New ID:", responseData.newid);
      } else {
        console.error("Failed to add data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    handleNextButtonClick();
  };

  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid mb-5 ">
            <div className="row ">
              <div
                className="col-lg-4 mt-3 col-10 ps-5   text-start"
                onClick={() => {
                  navigateTo("/");
                }}
              >
                <img
                  src={brand}
                  alt=""
                  className=""
                  style={{ width: "4vw", cursor: "pointer" }}
                />
                <span
                  className="fs-3 p-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigateTo("/");
                  }}
                >
                  Policy Dekhoo
                </span>
              </div>

              <div className="col-lg-8 col-2 pe-5  d-flex justify-content-end ">
                <div className="row mt-3 ">
                  <div className="col-12  d-none d-lg-block">
                    <ul className="list-inline">
                      <li
                        className="list-inline-item p-2  fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Home
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        About Us
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Support
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Claim
                      </li>
                      <li
                        className="list-inline-item get fs-6 p-2"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Contact Us
                      </li>
                    </ul>
                  </div>
                  <div className="col d-lg-none">
                    <img
                      src={hambergar}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8  text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7 col-1">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li className=" p-2  fs-6" style={{ cursor: "pointer" }}>
                    Home
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    PD Advantages
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Customer Rating
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    About Us
                  </li>
                  <li
                    className=" p-2 fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={scrollToContactForm}
                  >
                    Support
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Claim
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2" onClick={scrollToContactForm}>
                  Contact Us
                </span>
              </div>
            </div>

            <div className="row fixed-bottom">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid">
            <div className="row ">
              <div className="col-md-10 ps-5 ">
                <div className="text-start  mx-5">
                  <span
                    className="fw-lighter"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigateTo("/");
                    }}
                  >
                    Home&#62;
                  </span>
                  <span className="" style={{ fontWeight: "400" }}>
                    Bike Insurace
                  </span>
                </div>
              </div>
            </div>

            <div className="row ps-5">
              <div className="col-lg-7 ">
                <div className="row">
                  <div className="col-md-4 col-4">
                    <hr
                      style={{ height: "2px", color: "#2D75E0", border: "" }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-md-4 col-4">
                    <hr style={{ height: "2px" }} className="w-100" />
                  </div>
                  <div className="col-md-4 col-4">
                    <hr style={{ height: "2px" }} className="w-100" />
                  </div>
                </div>

                <div className="heade">
                  <div className=" ">
                    <p className="fs-2" style={{ textAlign: "left" }}>
                      Secure Your Bike with Insurance Designed to Meet{" "}
                      <span className="fw-bold">your requirements.</span>
                    </p>
                  </div>
                </div>

                <form action="">
                  <div className="row mt-4 needs-validation" novalidate>
                    <div className="col-md-6">
                      <div className="text-start">
                        <label htmlFor="" className="mb-3 fw-bold">
                          Registration No
                        </label>
                        <input
                          type="text"
                          className="form-control p-3"
                          placeholder="Enter Your Registration Number"
                          name="Registration_No"
                          value={bike_insurance.Registration_No}
                          style={{
                            border:
                              InvalidRegiter === true ? "2px solid red" : "",
                          }}
                          onChange={(e) => {
                            addValues(e);
                          }}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="text-start">
                        <label htmlFor="" className="mb-3 fw-bold">
                          Manufacturer
                        </label>
                        <select
                          className="form-select p-3"
                          required
                          value={selectedManufacturer}
                          onChange={handleManufacturerChange}
                        >
                          <option value="">Enter Manufacturer Name</option>
                          <option value="BAJAJ">BAJAJ</option>
                          <option value="HEROELECTRIC">HERO ELECTRIC</option>
                          <option value="HEROHONDA">HERO HONDA</option>
                          <option value="HEROMOTOCORP">HERO MOTOCORP</option>
                          <option value="KAWASAKI">KAWASAKI</option>
                          <option value="KTM">KTM</option>
                          <option value="ROYALENFIELD">ROYAL ENFIELD</option>
                          <option value="SUZUKI">SUZUKI</option>
                          <option value="TRIUMPH">TRIUMPH</option>
                          <option value="TVS">TVS</option>
                          <option value="JAWA">JAWA</option>
                          <option value="HONDA">HONDA</option>
                          <option value="YAMAHA">YAMAHA</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-6">
                      <div className="text-start">
                        <label htmlFor="" className="mb-3 fw-bold">
                          Model
                        </label>
                        <select
                          className="form-select p-3"
                          name="Model"
                          value={bike_insurance.Model}
                          onChange={(e) => {
                            addValues(e);
                          }}
                        >
                          {models.map((model, index) => (
                            <option key={index} value={model}>
                              {model}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6  mb-5">
                      <div className="">
                        <div className="mb-4">
                          <label htmlFor="" className="mb-3 fw-bold">
                            Do You Have Previous year Policy
                          </label>
                        </div>
                        <input
                          type="radio"
                          id=""
                          name="Previous_Year_Policy"
                          value={bike_insurance.Previous_Year_Policy}
                          required
                          checked={selectYes === "Yes" ? "true" : null}
                          onClick={() => handleRadioClick("Yes")}
                        />
                        <label for="" className="me-5 ps-3">
                          Yes
                        </label>

                        <input
                          type="radio"
                          id=""
                          name="Previous_Year_Policy"
                          value={bike_insurance.Previous_Year_Policy}
                          required
                          checked={selectYes === "No" ? "true" : null}
                          onClick={() => handleRadioClick("No")}
                        />
                        <label for="" className="ps-3">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      className="p-2 fs-5 but10 w-25 mt-5"
                      onClick={(e) => {
                        handlesubmit1(e);
                      }}
                    >
                      Next
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-lg-5   d-none d-lg-block">
                <div className="">
                  <img src={bikein} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row  mx-5">
              <div className="col-lg-12  text-start p-3">
                <h2>Bike Insurance Plan</h2>
              </div>
              <div className="row ">
                <div className="col-md-12 ">
                  <div className="text-start">
                    <p className="fs-4">
                      Safeguard your beloved bike with Policy Dekhoo's
                      comprehensive insurance policies tailored to meet your
                      needs. Our coverage encompasses protection against a wide
                      array of risks, including accidents, theft, fire, natural
                      calamities, and third-party liabilities. Moreover, benefit
                      from additional perks such as coverage for accessories and
                      personal accident protection for the owner/driver.
                    </p>
                  </div>

                  <div className="text-start ">
                    <p className="fs-4">
                      Rest assured, with Policy Dekhoo's extensive network and
                      round-the-clock customer support, you'll experience a
                      worry-free riding journey. Whether it's navigating
                      bustling city streets or exploring scenic routes, ride
                      with confidence knowing that Policy Dekhoo has you covered
                      every step of the way.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="container-fluid">
          <div className="row px-5 mb-5 ">
            <div className="col-12">
              <h5 style={{ textAlign: "justify" }} className="fs-1 ">
                FAQ
              </h5>
            </div>
          </div>
          <div className="row px-5 ">
            <div className="col-lg-8 ">
              <div className="row ">
                <div className="col-md-12">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Why do I need insurance?
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        How is insurance premium calculated?
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        What is a waiting period in insurance?
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        How do I file an insurance claim?
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Can I customize my insurance coverage?
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>

            <div className="col-lg-4  d-none d-lg-block ">
              <div>
                <img src={questi} alt="" />
              </div>
            </div>
          </div>
          </div> */}
          </div>

          <footer>
            <div className="container-fluid background-imag1 mt-5 ">
              <div className="row p-5">
                <div className="col-sm-6 order-2 order-sm-1 text-start">
                  <div className="row ps-5">
                    <div className="col-lg-6 ">
                      <ul className="list-unstyled text-start list">
                        <li
                          className="mb-3 fw-bold fs-5"
                          onClick={() => {
                            navigateTo("/");
                          }}
                        >
                          Home{" "}
                        </li>
                        <li className="mb-3 fw-bold fs-5">PD Advantages</li>
                        <li className="mb-3 fw-bold fs-5">Customer Rating</li>
                        <li className="mb-3 fw-bold fs-5">About Us</li>
                      </ul>
                    </div>

                    <div className="col-lg-6">
                      <ul className="list-unstyled text-start list">
                        <li className="mb-3 fw-bold fs-5">Support</li>
                        <li className="mb-3 fw-bold fs-5">Claim</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 p-5 text-sm-end text-center order-1 ">
                  <img
                    src={brand}
                    alt=""
                    style={{ width: "5vw" }}
                    className="logo"
                  />
                </div>
              </div>
            </div>
          </footer>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h5 className="fs-5">
                  &#169;2020 Policy Dekhoo | Developed By{" "}
                  <a
                    href="https://www.unairesync.com/"
                    className="link"
                    target="blank"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Unaire Sync
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default BikeInsuresnce;
