import React from "react";
// import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import "./Carform3.css";
import { useState, useRef } from "react";
import carin from "../resources/in.svg";
// import Accordion from "react-bootstrap/Accordion";
// import question from "../resources/ques.svg";
import log from "../resources/Logo.svg";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";
import { useNavigate, useParams } from "react-router-dom";

function CarForm3() {
  const [toogle, settoggle] = useState(false);
  const [invaildemail, setinvaildemail] = useState(false);

  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  const contactFormRef = useRef(null);

  // Function to scroll to the contact form
  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const handleNextButtonClick = (e) => {
  //   e.preventDefault()
  //   const a = mailvalid2(carInsurance3Data.Email_Address)
  //   const b = handleAlphaOnly(carInsurance3Data.First_Name)
  //   const c = handleAlphaOnly(carInsurance3Data.Last_Name)

  //   console.log(a,"")
  //   if()

  //   const form = document.querySelector("form");
  //   if (form.checkValidity()) {
  //     navigateTo("/CarForm/Carform2/Carform3/ThankYou");
  //   } else {
  //     form.reportValidity();
  //   }
  // };

  const handleNextButtonClick = (e) => {
    e.preventDefault();
    setinvaildemail(false);
    const emailIsValid = mailvalid2(carInsurance3Data.Email_Address);
    // const firstNameIsValid = handleAlphaOnly(carInsurance3Dat a.First_Name);
    // const lastNameIsValid = handleAlphaOnly(carInsurance3Data.Last_Name);
    console.log(emailIsValid, "");
    if (emailIsValid) {
      const form = document.querySelector("form");

      if (form.checkValidity()) {
        navigateTo("/CarForm/Carform2/Carform3/ThankYou");
      } else {
        form.reportValidity();
      }
    } else {
      console.log("Email or name is invalid");
      setinvaildemail(true);
    }
    console.log(invaildemail, "erroremail");
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [name, setname] = useState("");
  const [variablename, setvariablename] = useState("");

  // const handleFirstName = (e) => {
  //   const value = e.target.value;
  //   if (/^[A-Za-z ]+$/.test(value) || value === "") {
  //     setFirstName(value);
  //     setErrors((prevErrors) => ({ ...prevErrors, firstName: "" }));
  //   } else {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       firstName: "Please enter only alphabets",
  //     }));
  //   }
  // };

  const handleAlphaOnly = (event) => {
    const { value } = event.target;

    const isnot = /^[a-zA-Z]*$/.test(event.key);
    const isnot2 = /^$/.test(event.key);
    if (isnot || isnot2) {
      setname(value);
    } else {
      event.preventDefault();
    }
  };

  // const handleLastNameChange = (e) => {
  //   const value = e.target.value;
  //   if (/^[A-Za-z ]+$/.test(value) || value === "") {
  //     setLastName(value);
  //     setErrors((prevErrors) => ({ ...prevErrors, lastName: "" }));
  //   } else {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       // lastName: "Please enter only alphabets",
  //     }));
  //   }
  // };

  // const handleEmailChange = (e) => {
  //   const value = e.target.value;
  //   setEmail(value);
  //   if (
  //     /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) ||
  //     value === ""
  //   ) {
  //     setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
  //   } else {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       // email: "Please enter a valid email address",
  //     }));
  //   }
  // };

  // const handleContactNumberChange = (e) => {
  //   const value = e.target.value;
  //   if (/^[0-9]*$/.test(value) || value === "") {
  //     setContactNumber(value);
  //     setErrors((prevErrors) => ({ ...prevErrors, contactNumber: "" }));
  //   } else {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       // contactNumber: "Please enter only numerical characters",
  //     }));
  //   }
  // };

  const mailvalid2 = (variablename) => {
    const checkmail = /^([A-Za-z0-9._]+@[A-Za-z]+\.[A-Za-z]{2,4})$/;
    if (variablename.match(checkmail)) {
      //email validate true
      console.log("helloooo");
      return true;
    } else {
      //email invalid
      console.log("invald");
      return false;
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault(); // Prevent default form submission
  //   let isValid = true; // Assume form is valid initially

  //   // Validate first name (alphabetic characters only)
  //   if (!/^[A-Za-z]+$/.test(carInsurance3Data.First_Name)) {
  //     isValid = false;
  //     // Display error message for first name
  //     console.log("Invalid First Name");
  //   }

  //   // Validate last name (alphabetic characters only)
  //   if (!/^[A-Za-z]+$/.test(carInsurance3Data.Last_Name)) {
  //     isValid = false;
  //     // Display error message for last name
  //     console.log("Invalid Last Name");
  //   }

  //   // Validate email address
  //   // if (!validateEmail(carInsurance3Data.Email_Address)) {
  //   //   isValid = false;
  //   //   // Display error message for email address
  //   //   console.log("Invalid Email Address");
  //   // }

  //   // Validate contact number (numeric characters only)
  //   if (!/^[0-9]+$/.test(carInsurance3Data.Contact_Number)) {
  //     isValid = false;
  //     // Display error message for contact number
  //     console.log("Invalid Contact Number");
  //   }

  //   // Proceed if all validations pass
  //   if (isValid) {
  //     console.log("Form is valid. Proceed with submission.");
  //     // Call your submission function here
  //   } else {
  //     console.log("Form is not valid. Please correct the errors.");
  //   }
  // };

  const [pincode, setPincode] = useState("");
  const handleNumericOnly = (event) => {
    const { value } = event.target;

    const isnot = /^[0-9]*$/.test(event.key);
    const isnot2 = /^$/.test(event.key);
    if (isnot || isnot2) {
      setPincode(value);
    } else {
      event.preventDefault();
    }
  };

  const [Contactcod, setContactcod] = useState("");
  const [ContactError, setContactError] = useState("");

  const handleContactcod = (event) => {
    const { value } = event.target;
    const isValid = /^[0-9]*$/.test(event.key) || /^$/.test(event.key);

    if (isValid) {
      setContactcod(value);
      setContactError(""); // Reset error message
    } else {
      event.preventDefault();
      setContactError("Contact should contain only numbers.");
    }

    if (value.length !== 10) {
      setContactError("Contact number should be 10 digits long.");
    } else {
      setContactError("");
    }
  };

  const { obj } = useParams();
  const obj1 = JSON.parse(decodeURIComponent(obj));
  console.log(obj1, "------------------------");

  // console.log(obj1)
  const [carInsurance3Data, setCarInsurance3Data] = useState({
    First_Name: "",
    Last_Name: "",
    Email_Address: "",
    Contact_Number: "",
  });

  const iddupd = obj1.iddupd;
  const rgno = obj1.rgno;
  const tableName = "car_insurance";

  const addValues = (e) => {
    setCarInsurance3Data({
      ...carInsurance3Data,
      [e.target.name]: e.target.value,
    });
    console.log(carInsurance3Data);
  };

  console.log(carInsurance3Data);

  const handlesubmit1 = async () => {
    console.log("data is:", carInsurance3Data);
    try {
      const response = await fetch(
        `https://policydekhoo-backend.onrender.com/Updatevechiledata?tableName=${tableName}&Registration_No=${rgno}&id=${iddupd}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(carInsurance3Data),
        }
      );

      if (response.ok) {
        console.log("Data updated successfully");
      } else {
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid mb-5 ">
            <div className="row ">
              <div
                className="col-lg-4 mt-3 col-10 ps-5   text-start"
                onClick={() => {
                  navigateTo("/");
                }}
              >
                <img
                  src={log}
                  alt=""
                  className=""
                  style={{ width: "4vw", cursor: "pointer" }}
                />
                <span
                  className="fs-3 p-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigateTo("/");
                  }}
                >
                  Policy Dekhoo
                </span>
              </div>

              <div className="col-lg-8 col-2 pe-5  d-flex justify-content-end ">
                <div className="row mt-3 ">
                  <div className="col-12  d-none d-lg-block">
                    <ul className="list-inline">
                      <li
                        className="list-inline-item p-2  fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Home
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        About Us
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Support
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Claim
                      </li>
                      <li
                        className="list-inline-item get fs-6 p-2"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Contact Us
                      </li>
                    </ul>
                  </div>
                  <div className="col d-lg-none">
                    <img
                      src={hambergar}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8  text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7 col-1">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li className=" p-2  fs-6" style={{ cursor: "pointer" }}>
                    Home
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    PD Advantages
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Customer Rating
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    About Us
                  </li>
                  <li
                    className=" p-2 fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={scrollToContactForm}
                  >
                    Support
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Claim
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2" onClick={scrollToContactForm}>
                  Contact Us
                </span>
              </div>
            </div>

            <div className="row fixed-bottom">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {/* form */}
      {toogle === false ? (
        <>
          <div className="container-fluid">
            <div className="row text-start  p-3 ">
              <div className="col mx-5">
                <span
                  onClick={() => {
                    navigateTo("/");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Home&#62;
                </span>
                <span
                  className="fw-lighter"
                  onClick={() => {
                    navigateTo("/CarForm");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Car Insurance
                </span>
                &#62;
                <span
                  className=""
                  onClick={() => {
                    navigateTo("/CarForm/CarForm2");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Previous Insurer
                </span>
                &#62;
                <span style={{ fontWeight: "500" }}>Personal Detail</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7  p-3 ">
                <div className="row mx-5 ">
                  <div className="col-md-4 col-4">
                    <hr
                      style={{ height: "2px", width: "10vw", border: "" }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-md-4 col-4">
                    <hr
                      style={{ height: "2px", width: "10vw" }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-md-4 col-4">
                    <hr
                      style={{ height: "2px", width: "10vw", color: "#2D75E0" }}
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="mx-5 ps-4">
                  <p className="fs-3" style={{ textAlign: "left" }}>
                    Get Your Car Insurance Quote Tailored to{" "}
                    <span className="fw-bold"> your requirements.</span>
                  </p>
                </div>

                <div>
                  <form action="">
                    <div className="row ps-3">
                      <div className="col-md-6 ps-5 text-start">
                        <div>
                          <label htmlFor="" className=" mb-3 fw-bold ps-3">
                            First Name
                          </label>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control  p-3 ps-3"
                            placeholder="Enter Your First Name"
                            // onChange={handleFirstNameChange}
                            required
                            name="First_Name"
                            value={carInsurance3Data.First_Name}
                            onChange={(e) => {
                              addValues(e);
                            }}
                            onKeyPress={handleAlphaOnly}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 ps-5 text-start">
                        <div>
                          <label htmlFor="" className=" mb-3 fw-bold ps-3">
                            Last Name
                          </label>
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Your Last Name"
                            className="form-control p-3"
                            // value={lastName}
                            // onChange={handleLastNameChange}
                            name="Last_Name"
                            value={carInsurance3Data.Last_Name}
                            onChange={(e) => {
                              addValues(e);
                            }}
                            onKeyPress={handleAlphaOnly}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row ps-3">
                      <div className="col-md-12 ps-5 text-start mt-4">
                        <div>
                          <label htmlFor="" className="mb-3 fw-bold  ps-3">
                            Email Address
                          </label>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control p-3"
                            placeholder="Enter Your Email Address"
                            name="Email_Address"
                            value={carInsurance3Data.Email_Address}
                            style={{
                              border:
                                invaildemail === true ? "2px solid red" : "",
                            }}
                            onChange={(e) => {
                              addValues(e);
                              // mailvalid2(e); // Call handleEmailChange with the event object
                            }}
                            required
                          />
                          {/* {emailError && <div className="text-danger">{emailError}</div>} */}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 mt-4 mb-5 text-start ">
                        <div>
                          <label htmlFor="" className="mb-3 fw-bold ps-5">
                            Contact Number
                          </label>
                        </div>
                        <div>
                          <div class="input-group flex-nowrap ps-5">
                            <span class="input-group-text" id="addon-wrapping">
                              +91
                            </span>
                            <input
                              type="text"
                              class="form-control p-3"
                              placeholder="Contact Number"
                              aria-describedby="addon-wrapping"
                              // value={contactNumber}
                              // onChange={handleContactNumberChange}
                              name="Contact_Number"
                              value={carInsurance3Data.Contact_Number}
                              onChange={(e) => {
                                handleContactcod(e);
                                addValues(e);
                              }}
                              required
                              maxLength={"10"}
                              // onKeyPress={handleContactcod}
                            />
                          </div>
                          <div className="ps-5">
                            {ContactError && (
                              <span style={{ color: "red" }}>
                                {ContactError}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      className="but3 w-25 p-2 fs-5"
                      onClick={(e) => {
                        handleNextButtonClick(e);
                        handlesubmit1(e); // Assuming loanType is defined somewhere
                      }}
                    >
                      Ckeck Premium
                    </button>
                  </form>
                </div>
              </div>

              <div className="col-md-4 ps-5 d-none d-md-block">
                <div>
                  <img src={carin} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* HPI */}
          <div className="container-fluid">
            <div className="row  mx-5">
              <div className="col-md-12   text-start p-3">
                <h2>Car Insurance Plan</h2>
              </div>
              <div className="row ">
                <div className="col-md-12 ">
                  <div className="text-start ">
                    <p className="fs-4">
                      Safeguard your vehicle and financial security with Policy
                      dekhoo's robust car insurance plans. Our comprehensive
                      coverage shields you against a myriad of risks, including
                      accidents, theft, fire, natural disasters, and third-party
                      liabilities. Customise your plan with versatile add-ons
                      like roadside assistance, ensuring tailored protection for
                      your specific needs.
                    </p>
                  </div>

                  <div className="text-start ">
                    <p className="fs-4">
                      In the event of an unfortunate incident, our streamlined
                      claims process ensures minimal disruption to your routine.
                      With Policy dekhoo, you can navigate the roads with
                      confidence, knowing that you're backed by a reliable
                      insurance provider committed to your peace of mind. Trust
                      us to protect your journey.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container-fluid">
        <div className="row px-5 mb-5 ms-3">
          <div className="col-12">
            <h5 style={{ textAlign: "justify" }} className="fs-1 ">
              FAQ
            </h5>
          </div>
        </div>
        <div className="row px-5 ">
          <div className="col-md-8 ">
            <div className="row ">
              <div className="col-md-12">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Why do I need insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How is insurance premium calculated?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What is a waiting period in insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How do I file an insurance claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I customize my insurance coverage?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>

          <div className="col-4 d-none d-md-block">
            <div>
              <img src={question} alt="" />
            </div>
          </div>
        </div>
      </div> */}

          {/* Footer */}
          <footer>
            <div className="container-fluid background-imag1 mt-5 ">
              <div className="row p-5">
                <div className="col-sm-6 order-2 order-sm-1 text-start">
                  <div className="row ps-5">
                    <div className="col-lg-6 ">
                      <ul className="list-unstyled text-start list">
                        <li
                          className="mb-3 fw-bold fs-5"
                          onClick={() => {
                            navigateTo("/");
                          }}
                        >
                          Home{" "}
                        </li>
                        <li className="mb-3 fw-bold fs-5">PD Advantages</li>
                        <li className="mb-3 fw-bold fs-5">Customer Rating</li>
                        <li className="mb-3 fw-bold fs-5">About Us</li>
                      </ul>
                    </div>

                    <div className="col-lg-6">
                      <ul className="list-unstyled text-start list">
                        <li className="mb-3 fw-bold fs-5">Support</li>
                        <li className="mb-3 fw-bold fs-5">Claim</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 p-5 text-sm-end text-center order-1 ">
                  <img
                    src={log}
                    alt=""
                    style={{ width: "5vw" }}
                    className="logo"
                  />
                </div>
              </div>
            </div>
          </footer>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h5 className="fs-6">
                  &#169;2020 Policy Dekhoo | Developed By{" "}
                  <a
                    href="https://www.unairesync.com/"
                    className="link"
                    target="blank"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Unaire Sync
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default CarForm3;
