import React, { useState, useRef } from "react";
import "./Bikeform2.css";
import bikein from "../resources/bikeinsurance.svg";
// import questi from "../resources/ques.svg";
// import Accordion from "react-bootstrap/Accordion";
import logo from "../resources/Logo.svg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate, useParams } from "react-router-dom";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";
import TextField from "@mui/material/TextField";

function BikeInsureance2() {
  const [selectedYear, setSelectedYear] = useState(null);
  const [toogle, settoggle] = useState(false);

  const handleChange = (e) => {
    setSelectedYear(parseInt(e.target.value));
  };

  const contactFormRef = useRef(null);

  // Function to scroll to the contact form
  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= 2000; year--) {
    years.push(year);
  }

  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };
  // const [selectYes, setselectYes] = useState(null);

  // const handleRadioClick = (value) => {
  //   if (value === selectYes) {
  //     setselectYes(null);
  //   } else {
  //     setselectYes(value);
  //   }
  // };

  const [selectYes, setSelectYes] = useState("");

  const handleRadioClick = (value) => {
    setSelectYes(value);
    if (value === "No") {
      setbike_insurance2({
        ...bike_insurance2,
        Any_Claims_This_Year: value,
      });
    } else {
      setbike_insurance2({
        ...bike_insurance2,
        Any_Claims_This_Year: value,
        Last_Year_NCB: null,
      });
    }
  };

  const handleNextButtonClick = () => {
    const form = document.querySelector("form");
    if (form.checkValidity()) {
      // Include iddupd and rgno with bike_insurance2 object
      const bike_insurance_with_variables = {
        ...bike_insurance2,
        iddupd: iddupd,
        rgno: rgno,
      };
      const serializedObj = encodeURIComponent(
        JSON.stringify(bike_insurance_with_variables)
      );
      navigateTo(
        `/BikeInsurance/BikeInsurance2/BikeInsurance3/${serializedObj}`
      );
    } else {
      form.reportValidity();
    }
  };

  const { obj } = useParams();
  const obj1 = JSON.parse(decodeURIComponent(obj));

  // console.log(obj1)
  const [bike_insurance2, setbike_insurance2] = useState({
    Policy_Expiry_Date: null,
    Year_Of_Manufacturing: null,
    Previous_Insurer: null,
    Any_Claims_This_Year: null,
    Last_Year_NCB: null,
  });

  const iddupd = obj1.id;
  const rgno = obj1.Registration_No;
  const tableName = "bike_insurance";

  const addValues = (e) => {
    setbike_insurance2({
      ...bike_insurance2,
      [e.target.name]: e.target.value,
    });
    console.log(e.target.value, e.target.name);
  };

  console.log(bike_insurance2);

  const handlesubmit2 = async () => {
    console.log("data is:", bike_insurance2);
    try {
      const response = await fetch(
        `https://policydekhoo-backend.onrender.com/Updatevechiledata?tableName=${tableName}&Registration_No=${rgno}&id=${iddupd}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bike_insurance2),
        }
      );

      if (response.ok) {
        console.log("Data updated successfully");
      } else {
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid mb-5 ">
            <div className="row ">
              <div
                className="col-lg-4 mt-3 col-10 ps-5   text-start"
                onClick={() => {
                  navigateTo("/");
                }}
              >
                <img
                  src={logo}
                  alt=""
                  className=""
                  style={{ width: "4vw", cursor: "pointer" }}
                />
                <span
                  className="fs-3 p-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigateTo("/");
                  }}
                >
                  Policy Dekhoo
                </span>
              </div>

              <div className="col-lg-8 col-2 pe-5  d-flex justify-content-end ">
                <div className="row mt-3 ">
                  <div className="col-12  d-none d-lg-block">
                    <ul className="list-inline">
                      <li
                        className="list-inline-item p-2  fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Home
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        About Us
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Support
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Claim
                      </li>
                      <li
                        className="list-inline-item get fs-6 p-2"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Contact Us
                      </li>
                    </ul>
                  </div>
                  <div className="col d-lg-none">
                    <img
                      src={hambergar}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8  text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7 col-1">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li className=" p-2  fs-6" style={{ cursor: "pointer" }}>
                    Home
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    PD Advantages
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Customer Rating
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    About Us
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }} onClick={scrollToContactForm}>
                    Support
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Claim
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2" onClick={scrollToContactForm}>Contact Us</span>
              </div>
            </div>

            <div className="row fixed-bottom">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid">
            <div className="row ">
              <div className="col-md-10 mt-4 ps-5">
                <div className="text-start  mx-5">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigateTo("/");
                    }}
                  >
                    Home&#62;
                  </span>
                  <span
                    className="fw-lighter"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigateTo("/BikeInsurance");
                    }}
                  >
                    BikeInsurance
                  </span>
                  &#62;
                  <span>PreviousInsurer</span>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-lg-7  ps-5">
                <div className="row">
                  <div className="col-sm-4 col-4">
                    <hr
                      style={{ height: "2px", border: "" }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-sm-4 col-4">
                    <hr
                      style={{ height: "2px", color: "##2D75E0" }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-sm-4 col-4">
                    <hr style={{ height: "2px" }} className="w-100" />
                  </div>
                </div>
                <div className="heade">
                  <div className="w-75 ">
                    <p className="fs-2" style={{ textAlign: "left" }}>
                      Secure Your Bike with Insurance Designed to Meet{" "}
                      <span className="fw-bold">your requirements.</span>
                    </p>
                  </div>
                </div>

                <form action="">
                  <div className="row mt-4 ">
                    <div className="col-md-6">
                      <div className="text-start">
                        <label htmlFor="" className="fw-bold mb-3">
                          Policy Expiry Date
                        </label>
                        <div style={{ marginTop: "8px" }}>
                          <TextField
                            id="outlined-basic"
                            type="date"
                            size="medium"
                            fullWidth
                            variant="outlined"
                            name="Policy_Expiry_Date"
                            value={bike_insurance2.Policy_Expiry_Date}
                            onChange={(e) => {
                              addValues(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 ">
                      <div className="text-start">
                        <label htmlFor="" className="fw-bold mb-4">
                          Year Of Manufacturing
                        </label>
                        <select
                          className="form-control p-3"
                          name="Year_Of_Manufacturing"
                          value={bike_insurance2.Year_Of_Manufacturing}
                          onChange={(e) => {
                            addValues(e);
                          }}
                          required
                        >
                          <option value="">Select year</option>
                          {years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6 mt-4">
                      <div className="row ">
                        <div className="text-start">
                          <label htmlFor="" className="mb-3 fw-bold ">
                            Previous Insurer
                          </label>
                          <select
                            className="form-select p-3"
                            name="Previous_Insurer"
                            value={bike_insurance2.Previous_Insurer}
                            onChange={(e) => {
                              addValues(e);
                            }}
                            required
                          >
                            <option value="">
                              Enter Your Previous Insurance
                            </option>
                            <option value="Acko General Insurance Limited">
                              Acko General Insurance Limited
                            </option>
                            <option value="Insurance Company of India Limited">
                              Insurance Company of India Limited
                            </option>
                            <option value="General Insurance Company Limited">
                              General Insurance Company Limited
                            </option>
                            <option value="Cholamandalam MS General Insurance Company Limited">
                              Cholamandalam MS General Insurance Company Limited
                            </option>
                            <option value="Future Generali India Insurance Company Limited">
                              Future Generali India Insurance Company Limited
                            </option>
                            <option value="Go Digit General Insurance Limited">
                              Go Digit General Insurance Limited
                            </option>
                            <option value="HDFC ERGO General Insurance Company Limited">
                              HDFC ERGO General Insurance Company Limited
                            </option>
                            <option value="ICICI LOMBARD General Insurance Company Limited">
                              ICICI LOMBARD General Insurance Company Limited
                            </option>
                            <option value="IFFCO TOKIO General Insurance Company Limited">
                              IFFCO TOKIO General Insurance Company Limited
                            </option>
                            <option value="Kotak Mahindra General Insurance Company Limited">
                              Kotak Mahindra General Insurance Company Limited
                            </option>
                            <option value="Kshema General Insurance Limited">
                              Kshema General Insurance Limited
                            </option>
                            <option value="Liberty General Insurance Limited">
                              Liberty General Insurance Limited
                            </option>
                            <option value="Magma HDI General Insurance Company Limited">
                              Magma HDI General Insurance Company Limited
                            </option>
                            <option value="National Insurance Company Limited">
                              National Insurance Company Limited
                            </option>
                            <option value="Navi General Insurance Limited">
                              Navi General Insurance Limited
                            </option>
                            <option value="Raheja QBE General Insurance Co. Ltd">
                              Raheja QBE General Insurance Co. Ltd
                            </option>
                            <option value="Reliance General Insurance Company Limited">
                              Reliance General Insurance Company Limited
                            </option>
                            <option value="Royal Sundaram General Insurance Company Limited">
                              Royal Sundaram General Insurance Company Limited
                            </option>
                            <option value="SBI General Insurance Company Limited">
                              SBI General Insurance Company Limited
                            </option>
                            <option value="Shriram General Insurance Company Limited">
                              Shriram General Insurance Company Limited
                            </option>
                            <option value="Tata AIG General Insurance Company Limited">
                              Tata AIG General Insurance Company Limited
                            </option>
                            <option value="The New India Assurance Company Limited">
                              The New India Assurance Company Limited
                            </option>
                            <option value="The Oriental Insurance Company Limited">
                              The Oriental Insurance Company Limited
                            </option>
                            <option value="United India Insurance Company Limited">
                              United India Insurance Company Limited
                            </option>
                            <option value="Universal Sompo General Insurance Company Limited">
                              Universal Sompo General Insurance Company Limited
                            </option>
                            <option
                              value="Zuno General Insurance Ltd. (formerly known as
                              Edelweiss General Insurance Company Limited)"
                            >
                              Zuno General Insurance Ltd. (formerly known as
                              Edelweiss General Insurance Company Limited)
                            </option>
                          </select>
                        </div>
                        {/* <span className='text-start mb-4 fw-bold '>Any Claims This year</span>
                                <div>
                                        <input type="radio" id="" name="" value="" className='' checked={selectYes==="Yes" ? "true": null}
                                            onClick={() => handleRadioClick('Yes')}/>
                                          <label for="" className='me-5'>Yes</label>
                                            
                                          <input type="radio" id="" name="" value="" checked={selectYes==="No" ? "true": null}
                                             onClick={() => handleRadioClick('No')}/>
                                          <label for="">No</label>
                                        </div> */}
                      </div>
                    </div>
                    <div className="col-md-6 mt-4 text-start">
                      <span className=" mb-5 fw-bold ">
                        Any Claims This year
                      </span>
                      <div className="mt-4 ms-5">
                        <input
                          type="radio"
                          id=""
                          name="Any_Claims_This_Year"
                          value={bike_insurance2.Any_Claims_This_Year}
                          className=""
                          required
                          checked={selectYes === "Yes"}
                          onClick={() => handleRadioClick("Yes")}
                        />
                        <label for="" className="me-5 ps-3">
                          Yes
                        </label>

                        <input
                          type="radio"
                          id=""
                          name="Any_Claims_This_Year"
                          value={bike_insurance2.Any_Claims_This_Year}
                          className=""
                          required
                          checked={selectYes === "No"}
                          onClick={() => handleRadioClick("No")}
                        />
                        <label for="" className="ps-3">
                          No
                        </label>
                      </div>
                    </div>
                    {/* <div className="text-start ">
                  <label htmlFor="" className="mb-3 fw-bold">
                    Last Year NCB
                  </label>
                </div>
                <select className="form-select p-3"required>
                  <option value="">Last Year NCB</option>
                  <option value="insurer1">Insurer 1</option>
                  <option value="insurer2">Insurer 2</option>
                  <option value="insurer3">Insurer 3</option>
                </select>
              </div> */}
                  </div>

                  <div className="row mb-5">
                    <div className="col-md-6 text-start mt-4">
                      <div className="text-start ">
                        <label htmlFor="" className="mb-3 fw-bold">
                          Last Year NCB
                        </label>
                      </div>
                      <select
                        className="form-select p-3"
                        required
                        disabled={selectYes === "Yes"}
                        name="Last_Year_NCB"
                        value={bike_insurance2.Last_Year_NCB}
                        onChange={(e) => {
                          addValues(e);
                        }}
                      >
                        <option value="">Last Year NCB</option>
                        <option value="insurer1">0</option>
                        <option value="insurer2">20</option>
                        <option value="insurer3">25</option>
                        <option value="insurer3">35</option>
                        <option value="insurer3">45</option>
                        <option value="insurer3">50</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <button
                      className="p-3 fs-5 but11 w-25"
                      onClick={() => {
                        handlesubmit2();
                        if (handleNextButtonClick()) {
                          navigateTo(
                            "/BikeInsurance/BikeInsurance2/BikeInsurance3"
                          );
                        }
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-5 ps-5 d-none d-lg-block">
                <div className="">
                  <img src={bikein} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mx-5">
              <div className="col-md-12   text-start p-3">
                <h2>Bike Insurance Plan</h2>
              </div>
              <div className="row ">
                <div className="col-md-12 ">
                  <div className="text-start ">
                    <p className="fs-4">
                      Safeguard your beloved bike with Policy Dekhoo's
                      comprehensive insurance policies tailored to meet your
                      needs. Our coverage encompasses protection against a wide
                      array of risks, including accidents, theft, fire, natural
                      calamities, and third-party liabilities. Moreover, benefit
                      from additional perks such as coverage for accessories and
                      personal accident protection for the owner/driver.
                    </p>
                  </div>

                  <div className="text-start w-75">
                    <p className="fs-4">
                      Rest assured, with Policy Dekhoo's extensive network and
                      round-the-clock customer support, you'll experience a
                      worry-free riding journey. Whether it's navigating
                      bustling city streets or exploring scenic routes, ride
                      with confidence knowing that Policy Dekhoo has you covered
                      every step of the way.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="container-fluid">
          <div className="row px-5 mb-5 ">
            <div className="col-lg-12">
              <h5 style={{ textAlign: "justify" }} className="fs-1 ">
                FAQ
              </h5>
            </div>
          </div>
          <div className="row px-5">
            <div className="col-lg-8 ">
              <div className="row ">
                <div className="col-md-12">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Why do I need insurance?
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        How is insurance premium calculated?
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        What is a waiting period in insurance?
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        How do I file an insurance claim?
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Can I customize my insurance coverage?
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>

            <div className="col-sm-4 d-none d-lg-block">
              <div>
                <img src={questi} alt="" />
              </div>
            </div>
          </div>
        </div> */}
          </div>

          <footer>
            <div className="container-fluid background-imag1 mt-5 ">
              <div className="row p-5">
                <div className="col-sm-6 order-2 order-sm-1 text-start">
                  <div className="row ps-5">
                    <div className="col-lg-6 ">
                      <ul className="list-unstyled text-start list">
                        <li
                          className="mb-3 fw-bold fs-5"
                          onClick={() => {
                            navigateTo("/");
                          }}
                        >
                          Home{" "}
                        </li>
                        <li className="mb-3 fw-bold fs-5">PD Advantages</li>
                        <li className="mb-3 fw-bold fs-5">Customer Rating</li>
                        <li className="mb-3 fw-bold fs-5">About Us</li>
                      </ul>
                    </div>

                    <div className="col-lg-6">
                      <ul className="list-unstyled text-start list">
                        <li className="mb-3 fw-bold fs-5">Support</li>
                        <li className="mb-3 fw-bold fs-5">Claim</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 p-5 text-sm-end text-center order-1 ">
                  <img
                    src={logo}
                    alt=""
                    style={{ width: "5vw" }}
                    className="logo"
                  />
                </div>
              </div>
            </div>
          </footer>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h5 className="fs-6">
                  &#169;2020 Policy Dekhoo | Developed By{" "}
                  <a
                    href="https://www.unairesync.com/"
                    className="link"
                    target="blank"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Unaire Sync
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default BikeInsureance2;
