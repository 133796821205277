import React from "react";
import { useNavigate } from "react-router-dom";
import { useState,useEffect,useRef } from "react";
import log from "../resources/Logo.svg";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";
import card from "../resources/ccard.svg";
import "./CreditCardform1.css";

function CreditCardForm1() {
  const [toogle, settoggle] = useState(false);

  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  const contactFormRef = useRef(null);

  // Function to scroll to the contact form
  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNextButtonClick = (e) => {
    e.preventDefault()
    setinvaildemail(false)
    const emailIsValid = mailvalid2(CreditCard.Email_Address);
    if(emailIsValid){

      const form = document.querySelector("form");
      if (form.checkValidity()) {
        // Proceed to the next page
        const serializedObj = encodeURIComponent(JSON.stringify(CreditCard));
  
        navigateTo(`/CreditCardForm1/CreditCardForm2/${serializedObj}`);
      } else {
        // Show validation errors
        form.reportValidity();
      }
    }else{
      console.log("Email or name is invalid");
      setinvaildemail(true)
    }
  };
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [IncomeNumber, setIncomeNumber] = useState("");
  const [errors, setErrors] = useState({});

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    if (/^[A-Za-z ]+$/.test(value) || value === "") {
      setFirstName(value);
      setErrors((prevErrors) => ({ ...prevErrors, firstName: "" }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        // firstName: "Please enter only alphabets",
      }));
    }
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    if (/^[A-Za-z ]+$/.test(value) || value === "") {
      setLastName(value);
      setErrors((prevErrors) => ({ ...prevErrors, lastName: "" }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        // lastName: "Please enter only alphabets",
      }));
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) ||
      value === ""
    ) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        // email: "Please enter a valid email address",
      }));
    }
  };

  const handleContactNumberChange = (e) => {
    const value = e.target.value;
    if (/^[0-9]*$/.test(value) || value === "") {
      setContactNumber(value);
      setErrors((prevErrors) => ({ ...prevErrors, contactNumber: "" }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        // contactNumber: "Please enter only numerical characters",
      }));
    }
  };
  // const handleIncomeNumber = (e) => {
  //   const value = e.target.value;
  //   if (/^[0-9]*$/.test(value) || value === "") {
  //     setIncomeNumber(value);
  //     setErrors((prevErrors) => ({ ...prevErrors, IncomeNumber: "" }));
  //   } else {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       // contactNumber: "Please enter only numerical characters",
  //     }));
  //   }
  // };

  const [pincode, setPincode] = useState("");
  console.log(pincode, "-=-=-=-==");
  const handleNumericOnly = (event) => {
    const { value } = event.target;
    const isnot = /^[0-9]*$/.test(event.key);
    const isnot2 = /^$/.test(event.key);
    if (isnot || isnot2) {
      setPincode(value);
    } else {
      event.preventDefault();
    }
  };

  const [pincod, setPincod] = useState("");
  const [pincodError, setPincodError] = useState("");

  const handleNumeric = (event) => {
    const { value } = event.target;
    const isValid = /^[0-9]*$/.test(event.key);


    if (isValid) {
      setPincod(value);
      setPincodError(""); // Reset error message
    } else {
      event.preventDefault();
      setPincodError("Pincode should contain only numbers.");
    }

    if (value.length !== 6) {
      setPincodError("Pincode should be 6 digits long.");
    } else {
      setPincodError("");
    }
  };

  const [Contactcod, setContactcod] = useState("");
  const [ContactError, setContactError] = useState("");

  const handleContactcod = (event) => {
    const { value } = event.target;
    const isValid = /^[0-9]*$/.test(event.key) || /^$/.test(event.key);

    if (isValid) {
      setContactcod(value);
      setContactError(""); // Reset error message
    } else {
      event.preventDefault();
      setContactError("Contact should contain only numbers.");
    }

    if (value.length !== 10) {
      setContactError("Contact number should be 10 digits long.");
    } else {
      setContactError("");
    }
  };
  const tableName = "credit_card_insurance";
  const [newidd, setnewidd] = useState();

  useEffect(() => {
    const getnewid = async () => {
      const url = `https://policydekhoo-backend.onrender.com/Getnewid?tableName=${tableName}`;

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch groups");
        }

        const data = await response.json();
        console.log("new id:", data);
        setnewidd(data);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    getnewid();
  }, []);

  const [CreditCard, setCreditCard] = useState({
    id:"",
    First_Name: "",
    Last_Name: "",
    Email_Address: "",
    Contact_Number: "",
    Occupation_Type: "",
    Monthly_Income: "",
    Residential_Pincode: "",
  });

  useEffect(() => {
    setCreditCard((prevCreditCard) => ({
      ...prevCreditCard,
      id: newidd, // Update id with newidd
    }));
  }, [newidd]);

  useEffect(() => {
    console.log("New ID22:", newidd);
    console.log("New ID22=========:", CreditCard.id);
  }, [newidd, CreditCard.id]);

  const addValues = (e) => {
    if(e.target.name==="Email_Address"){
      setinvaildemail(false)
    }
    setCreditCard({ ...CreditCard, [e.target.name]: e.target.value });
  };
  console.log(CreditCard);

  const handlesubmit1 = async (e) => {
    e.preventDefault();

    console.log(CreditCard);
    try {
      const response = await fetch(
        "https://policydekhoo-backend.onrender.com/Setcreditcardinsurancedata",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(CreditCard),
        }
      );

      if (response) {
        const responseData = await response.json();
        console.log("Data added successfully");
        console.log("New ID:", responseData.newid);
      } else {
        console.error("Failed to add data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    handleNextButtonClick(e);
  };

  const [invaildemail, setinvaildemail] = useState(false)


  const mailvalid2=(variablename)=>{
    const checkmail= /^([A-Za-z0-9._]+@[A-Za-z]+\.[A-Za-z]{2,4})$/
    if(variablename.match(checkmail)){
        //email validate true
        console.log("helloooo");
        return(true)
    }
    else{
        //email invalid
        console.log("invald");
        return(false)
    }

  }

  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid mb-5 ">
            <div className="row ">
              <div
                className="col-lg-4 mt-3 col-10 ps-5   text-start"
                onClick={() => {
                  navigateTo("/");
                }}
              >
                <img
                  src={log}
                  alt=""
                  className=""
                  style={{ width: "4vw", cursor: "pointer" }}
                />
                <span
                  className="fs-3 p-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigateTo("/");
                  }}
                >
                  Policy Dekhoo
                </span>
              </div>

              <div className="col-lg-8 col-2 pe-5  d-flex justify-content-end ">
                <div className="row mt-3 ">
                  <div className="col-12  d-none d-lg-block">
                    <ul className="list-inline">
                      <li
                        className="list-inline-item p-2  fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigateTo("/");
                        }}
                      >
                        Home
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        About Us
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Support
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Claim
                      </li>
                      <li
                        className="list-inline-item get fs-6 p-2"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Contact Us
                      </li>
                    </ul>
                  </div>
                  <div className="col d-lg-none">
                    <img
                      src={hambergar}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8  text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7 col-1">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li
                    className=" p-2  fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigateTo("/");
                    }}
                  >
                    Home
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    PD Advantages
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Customer Rating
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    About Us
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }} onClick={scrollToContactForm}>
                    Support
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Claim
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2" onClick={scrollToContactForm}>Contact Us</span>
              </div>
            </div>

            <div className="row fixed-bottom">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid ">
            <div className="row text-start     p-3  ">
              <div className="col ps-lg-5">
                <span
                  className=""
                  onClick={() => {
                    navigateTo("/");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Home&#62;
                </span>
                <span className="" style={{ fontWeight: "500" }}>
                  Credit Card
                </span>
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-7   p-3 ">
                <div className="row ps-lg-5">
                  <div className="col-md-4 col-4 ">
                    <hr
                      className="w-100"
                      style={{
                        height: "2px",
                        // width: "10vw",
                        color: "#2D75E0",
                        border: "",
                      }}
                    />
                  </div>
                  <div className="col-md-4 col-4 ">
                    <hr style={{ height: "2px" }} className="w-100" />
                  </div>
                  <div className="col-md-4 col-4">
                    <hr style={{ height: "2px" }} className="w-100" />
                  </div>
                </div>
                <div className="">
                  <p className="fs-3 ps-lg-5" style={{ textAlign: "left" }}>
                    Secure Your Finances with Credit Card Tailored to
                    <span className="fw-bold"> your requirements.</span>
                  </p>
                </div>

                <div className="container">
                  <form action="">
                    <div
                      className="row mt-5 mb-3 ps-md-5 needs-validation"
                      novalidate
                    >
                      <div className=" col-lg-6  text-start">
                        <div>
                          <label htmlFor="" className="mb-3 fw-bold">
                            First Name
                          </label>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control p-3  "
                            placeholder="Enter Your First Name"
                            required
                            name="First_Name"
                            value={CreditCard.First_Name}
                            onChange={(e) => {
                              addValues(e);
                            }}
                          />
                        </div>
                      </div>

                      <div className=" col-lg-6   text-start">
                        <div>
                          <label htmlFor="" className="mb-3 fw-bold">
                            Last Name
                          </label>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control p-3  "
                            placeholder="Enter Your Last Name"
                            required
                            name="Last_Name"
                            value={CreditCard.Last_Name}
                            onChange={(e) => {
                              addValues(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4 ps-md-5">
                      <div className="col-md-6 ">
                        <div className="text-start">
                          <label htmlFor="" className="fw-bold mb-3">
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control p-3"
                            placeholder="Enter Email Address"
                            name="Email_Address"
                            value={CreditCard.Email_Address}
                            onChange={(e) => {
                              addValues(e);
                            }}
                            required
                          style={{border:invaildemail===true?"2px solid red":"" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="text-start">
                          <label htmlFor="" className="fw-bold mb-3">
                            Contact Number
                          </label>
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              +91
                            </span>
                            <input
                              type="text"
                              className="form-control p-3"
                              placeholder="Enter Your Contact Number"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              name="Contact_Number"
                              value={CreditCard.Contact_Number}
                              onChange={(e) => {
                                handleContactcod(e)
                                addValues(e);
                              }}
                              maxLength={"10"}
                              onKeyPress={handleNumericOnly}
                            />
                          </div>
                            <div className="">
                            {ContactError && (
                              <span style={{ color: "red" }}>
                                {ContactError}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-5 ps-md-5">
                      <div className="col-md-6  ms-0 text-start">
                        <div>
                          <label htmlFor="" className="mb-3 fw-bold">
                            Occupation Type
                          </label>
                        </div>
                        <div>
                          <select
                            className="form-select  p-3"
                            name="Occupation_Type"
                            value={CreditCard.Occupation_Type}
                            onChange={(e) => {
                              addValues(e);
                            }}
                            required
                          >
                            <option value="">Select Occupation Type</option>
                            <option value="Salaried">Salaried</option>
                            <option value="Self Employed">Self Employed</option>
                          </select>
                        </div>
                      </div>

                      <div className=" col-md-6  text-start">
                        <div>
                          <label htmlFor="" className="mb-3 fw-bold">
                            Monthly Income
                          </label>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control p-3"
                            placeholder="Enter Your Monthly Income "
                            required
                            maxLength={"8"}
                            name="Monthly_Income"
                            value={CreditCard.Monthly_Income}
                            onChange={(e) => {
                              addValues(e);
                            }}
                            onKeyPress={handleNumericOnly}

                            // value={IncomeNumber}
                            //   onChange={handleIncomeNumber}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4 ps-md-5">
                      <div className="col-md-6 ">
                        <div className="text-start">
                          <label htmlFor="" className="fw-bold mb-3">
                            Current Residential Pincode
                          </label>
                          <input
                            type="text"
                            className="form-control p-3"
                            name="Residential_Pincode"
                            value={CreditCard.Residential_Pincode}
                            onChange={(e) => {
                              addValues(e);
                              handleNumeric(e)
                            }}
                            placeholder="Enter Residential Pincode "
                            required
                            // value={pincode}
                            maxLength={"6"}
                            onKeyPress={handleNumeric}
                          />
                          {pincodError && (
                            <span style={{ color: "red" }}>{pincodError}</span>
                          )}
                        </div>
                      </div>
                    </div>

                    <button
                      className="p-2 mt-5 but33 w-25 fs-5"
                      onClick={(e) => {
                        handlesubmit1(e);
                        // if (handleNextButtonClick()) {
                        //   navigateTo("/CreditCardForm2");
                        // }
                      }}
                    >
                      Next
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-lg-4  d-none d-lg-block">
                <div className="position-relative mt-5 py-5">
                  <img src={card} alt="" className="" />
                </div>
              </div>
            </div>
          </div>

          {/* HPI */}
          <div className="container-fluid">
            <div className="row  mx-5">
              <div className="col-md-12   text-start p-3 mt-5">
                <h2>Credit Card Plan</h2>
              </div>
              <div className="row ">
                <div className="col-lg-12 ">
                  <div className="text-start ">
                    <p className="fs-4">
                      Welcome to our Credit Card section! Discover a world of
                      financial freedom and convenience with our range of credit
                      card offerings. Whether you're looking for travel rewards,
                      cash back incentives, or low APR rates, we have the
                      perfect card to suit your lifestyle and financial goals.
                      With easy online application processes and competitive
                      benefits, managing your expenses has never been more
                      rewarding.
                    </p>
                  </div>

                  <div className="text-start">
                    <p className="fs-4">
                      Enjoy perks such as exclusive discounts, fraud protection,
                      and 24/7 customer support. Explore our diverse selection
                      of credit cards today and take the first step towards
                      smarter spending and greater financial flexibility.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 
      <div className="container-fluid">
        <div className="row px-5 mb-5">
          <div className="col-md-12">
            <h5 style={{ textAlign: "justify" }} className="fs-1 ">
              FAQ
            </h5>
          </div>
        </div> */}
          {/* <div className="row px-5 ">
          <div className="col-lg-8 ">
            <div className="row ">
              <div className="col-md-12">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Why do I need insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How is insurance premium calculated?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What is a waiting period in insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How do I file an insurance claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I customize my insurance coverage?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>

          <div className="col-4  d-none d-lg-block">
            <div>
              <img src={question} alt="" />
            </div>
          </div>
        </div>
      </div> */}

          {/* Footer */}

          <footer>
            <div className="container-fluid background-imag1 mt-5 ">
              <div className="row p-5">
                <div className="col-sm-6 order-2 order-sm-1 text-start">
                  <div className="row ps-5">
                    <div className="col-lg-6 ">
                      <ul className="list-unstyled text-start list">
                        <li
                          className="mb-3 fw-bold fs-5"
                          onClick={() => {
                            navigateTo("/");
                          }}
                        >
                          Home{" "}
                        </li>
                        <li className="mb-3 fw-bold fs-5">PD Advantages</li>
                        <li className="mb-3 fw-bold fs-5">Customer Rating</li>
                        <li className="mb-3 fw-bold fs-5">About Us</li>
                      </ul>
                    </div>

                    <div className="col-lg-6">
                      <ul className="list-unstyled text-start list">
                        <li className="mb-3 fw-bold fs-5">Support</li>
                        <li className="mb-3 fw-bold fs-5">Claim</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 p-5 text-sm-end text-center order-1 ">
                  <img
                    src={log}
                    alt=""
                    style={{ width: "5vw" }}
                    className="logo"
                  />
                </div>
              </div>
            </div>
          </footer>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h5 className="fs-6">
                  &#169;2020 Policy Dekhoo | Developed By{" "}
                  <a
                    href="https://www.unairesync.com/"
                    className="link"
                    target="blank"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Unaire Sync
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default CreditCardForm1;
