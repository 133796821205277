import React from "react";
import "./LoanFom1.css";
import { useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import log from "../resources/Logo.svg";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";
import loan from "../resources/loanphoto.svg";
function LoanForm1() {
  const contactFormRef = useRef(null);

  // Function to scroll to the contact form
  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [toogle, settoggle] = useState(false);
  const [FirstName, SetFirstName] = useState("");
  const [LastName, SetLastName] = useState("");
  const [EmailAddress, SetEmailAddress] = useState("");
  const [ContactNum, SetContactNum] = useState("");
  const [Pinncode, SetPinncode] = useState("");

  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  const handleNextButtonClick = (e,emailIsValid) => {
    e.preventDefault()
    setinvaildemail(false)
    // const emailIsValid = mailvalid2(loanType.Email_Address);
    if(emailIsValid){
      
      const form = document.querySelector("form");
      if (form.checkValidity()) {
        // Proceed to the next page
        navigateTo("/LoanForm1/ThankYou");
      } else {
        // Show validation errors
        form.reportValidity();
      }
    } else {
      console.log("Email or name is invalid");
      setinvaildemail(true);
    }
  };
  const [loanType, setLoanType] = useState("");
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [higherLimit, setHigherLimit] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [Pincode, setPincode] = useState("");

  const handleLoanTypeChange = (event) => {
    const selectedLoanType = event.target.value;
    setLoanType(selectedLoanType);
    setShowAdditionalFields(true);
    switch (selectedLoanType) {
      case "Personal Loan":
        setHigherLimit("");
        setRegistrationNumber("");
        break;
      case "Car Loan":
        setHigherLimit("");
        setRegistrationNumber("");
        break;
      case "Home Loan":
        setHigherLimit("");
        setRegistrationNumber("");
        break;
      case "Business Loan":
        setHigherLimit("");
        setRegistrationNumber("");
        setPincode("");
        break;
      default:
        setShowAdditionalFields(false);
    }
  };

  // const [selectYes, setselectYes] = useState(null);
  // const handleRadioClick = (value) => {
  //   if (value === selectYes) {
  //     setselectYes(null);
  //   } else {
  //     setselectYes(value);
  //   }
  // };
  const [selectYes, setSelectYes] = useState(null);

  const handleRadioClick = (value) => {
    setSelectYes(value);
    if (value === "No") {
      setCarloandata({
        ...Carloandata,
        Have_Any_Vehicle: value,
        Vehicle_Type: null,
        Registration_Number: null,
      });
    } else {
      setCarloandata({
        ...Carloandata,
        Have_Any_Vehicle: value,
      });
    }
  };

  //   const [toogle, settoggle] = useState(false);
  // const [pincode,setPincode]=useState("");
  console.log(Pincode, "-=-=-=-==");
  const handleNumericOnly = (event) => {
    const { value } = event.target;

    const isnot = /^[0-9]*$/.test(event.key);
    const isnot2 = /^$/.test(event.key);
    if (isnot || isnot2) {
      setPincode(value);
    } else {
      event.preventDefault();
    }
  };
  const [pincod, setPincod] = useState("");
  const [pincodError, setPincodError] = useState("");

  const handleNumeric = (event) => {
    const { value } = event.target;
    const isValid = /^[0-9]*$/.test(event.key) || /^$/.test(event.key);

    if (isValid) {
      setPincod(value);
      setPincodError(""); // Reset error message
    } else {
      event.preventDefault();
      setPincodError("Pincode should contain only numbers.");
    }

    if (value.length !== 6) {
      setPincodError("Pincode should be 6 digits long.");
    } else {
      setPincodError("");
    }
  };
  const [Contactcod, setContactcod] = useState("");
  const [ContactError, setContactError] = useState("");

  const handleContactcod = (event) => {
    const { value } = event.target;
    const isValid = /^[0-9]*$/.test(event.key) || /^$/.test(event.key);

    if (isValid) {
      setContactcod(value);
      setContactError(""); // Reset error message
    } else {
      event.preventDefault();
      setContactError("Contact should contain only numbers.");
    }

    if (value.length !== 10) {
      setContactError("Contact number should be 10 digits long.");
    } else {
      setContactError("");
    }
  };
  let emailIsValid = false;
  const [Personalloandata, setPersonalloandata] = useState({
    First_Name: "",
    Last_Name: "",
    Email_Address: "",
    Contact_Number: "",
    Occupation_Type: "",
    Income: "",
    Pincode: "",
  });

  const addValues = (e) => {
    setPersonalloandata({
      ...Personalloandata,
      [e.target.name]: e.target.value,
    });
  };

  console.log(Personalloandata);

  const [Carloandata, setCarloandata] = useState({
    First_Name: "",
    Last_Name: "",
    Email_Address: "",
    Contact_Number: "",
    Have_Any_Vehicle: "",
    Vehicle_Type: "",
    Registration_Number: "",
    Pincode: "",
  });

  const addValues2 = (e) => {
    setCarloandata({
      ...Carloandata,
      [e.target.name]: e.target.value,
    });
  };

  console.log(Carloandata);

  const [homeloandata, sethomeloandata] = useState({
    First_Name: "",
    Last_Name: "",
    Email_Address: "",
    Contact_Number: "",
    Pincode: "",
  });

  console.log(homeloandata);

  const [BussinessLoandata, setBussinessLoandata] = useState({
    First_Name: "",
    Last_Name: "",
    Email_Address: "",
    Contact_Number: "",
    Pincode: "",
  });

  console.log(BussinessLoandata);

  const handlesubmit1 = async (e, loanType) => {
    if (loanType === "Personal Loan") {
      Personalloandata.First_Name = FirstName;
      Personalloandata.Last_Name = LastName;
      Personalloandata.Email_Address = EmailAddress;
      Personalloandata.Contact_Number = ContactNum;
      Personalloandata.Pincode = Pinncode;
      emailIsValid = mailvalid2(EmailAddress);

      try {
        console.log("LOAN name:", Personalloandata);
        const response = await fetch(
          "https://policydekhoo-backend.onrender.com/Setpersonalloandata",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(Personalloandata),
          }
        );

        if (response) {
          console.log("Data added successfully");
        } else {
          console.error("Failed to add data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else if (loanType === "Car Loan") {
      Carloandata.First_Name = FirstName;
      Carloandata.Last_Name = LastName;
      Carloandata.Email_Address = EmailAddress;
      Carloandata.Contact_Number = ContactNum;
      Carloandata.Pincode = Pinncode;
      emailIsValid = mailvalid2(EmailAddress);

      try {
        console.log("LOAN name:", Carloandata);

        const response = await fetch(
          "https://policydekhoo-backend.onrender.com/Setcarloandata",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(Carloandata),
          }
        );

        if (response) {
          console.log("Data added successfully");
        } else {
          console.error("Failed to add data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else if (loanType === "Home Loan") {
      console.log("homelone");
      homeloandata.First_Name = FirstName;
      homeloandata.Last_Name = LastName;
      homeloandata.Email_Address = EmailAddress;
      homeloandata.Contact_Number = ContactNum;
      homeloandata.Pincode = Pinncode;
      emailIsValid = mailvalid2(EmailAddress);

      try {
        console.log("Loan name:", homeloandata);
        const response = await fetch(
          "https://policydekhoo-backend.onrender.com/Sethomeloandata",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(homeloandata),
          }
        );

        if (response) {
          console.log("Data added successfully");
        } else {
          console.error("Failed to add data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else if (loanType === "Business Loan") {
      BussinessLoandata.First_Name = FirstName;
      BussinessLoandata.Last_Name = LastName;
      BussinessLoandata.Email_Address = EmailAddress;
      BussinessLoandata.Contact_Number = ContactNum;
      BussinessLoandata.Pincode = Pinncode;
      emailIsValid = mailvalid2(EmailAddress);

      try {
        console.log("Loan name:", BussinessLoandata);
        const response = await fetch(
          "https://policydekhoo-backend.onrender.com/Setbusinessloandata",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(BussinessLoandata),
          }
        );

        if (response) {
          console.log("Data added successfully");
        } else {
          console.error("Failed to add data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const [name, setname] = useState("");
  const handleAlphaOnly = (event) => {
    const { value } = event.target;

    const isnot = /^[a-zA-Z]*$/.test(event.key);
    const isnot2 = /^$/.test(event.key);
    if (isnot || isnot2) {
      setname(value);
    } else {
      event.preventDefault();
    }
  };

  const [invaildemail, setinvaildemail] = useState(false);

  const mailvalid2 = (variablename) => {
    const checkmail = /^([A-Za-z0-9._]+@[A-Za-z]+\.[A-Za-z]{2,4})$/;
    if (variablename.match(checkmail)) {
      //email validate true
      console.log("helloooo");
      return true;
    } else {
      //email invalid
      console.log("invald");
      return false;
    }
  };

  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid mb-5 ">
            <div className="row ">
              <div
                className="col-lg-4 mt-3 col-10 ps-5   text-start"
                onClick={() => {
                  navigateTo("/");
                }}
              >
                <img
                  src={log}
                  alt=""
                  className=""
                  style={{ width: "4vw", cursor: "pointer" }}
                />
                <span
                  className="fs-3 p-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigateTo("/");
                  }}
                >
                  Policy Dekhoo
                </span>
              </div>

              <div className="col-lg-8 col-2 pe-5  d-flex justify-content-end ">
                <div className="row mt-3 ">
                  <div className="col-12  d-none d-lg-block">
                    <ul className="list-inline">
                      <li
                        className="list-inline-item p-2  fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Home
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        About Us
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Support
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Claim
                      </li>
                      <li
                        className="list-inline-item get fs-6 p-2"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Contact Us
                      </li>
                    </ul>
                  </div>
                  <div className="col d-lg-none">
                    <img
                      src={hambergar}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8  text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7 col-1">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li className=" p-2  fs-6" style={{ cursor: "pointer" }}>
                    Home
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    PD Advantages
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Customer Rating
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    About Us
                  </li>
                  <li
                    className=" p-2 fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={scrollToContactForm}
                  >
                    Support
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Claim
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2" onClick={scrollToContactForm}>
                  Contact Us
                </span>
              </div>
            </div>

            <div className="row fixed-bottom">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid ">
            <div className="row text-start     p-3  ">
              <div className="col ps-lg-5" style={{ cursor: "pointer" }}>
                <span
                  className=""
                  onClick={() => {
                    navigateTo("/");
                  }}
                >
                  Home&#62;
                </span>
                <span className="" style={{ fontWeight: "500" }}>
                  Loan
                </span>
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-7   p-3 ">
                <div className="row ps-lg-5">
                  <div className="col-md-4 col-4 ">
                    <hr
                      className="w-100"
                      style={{
                        height: "2px",
                        // width: "10vw",
                        color: "#2D75E0",
                        border: "",
                      }}
                    />
                  </div>
                  <div className="col-md-4 col-4 ">
                    <hr style={{ height: "2px" }} className="w-100" />
                  </div>
                  <div className="col-md-4 col-4">
                    <hr style={{ height: "2px" }} className="w-100" />
                  </div>
                </div>
                <div className="">
                  <p className="fs-3 ps-lg-5" style={{ textAlign: "left" }}>
                    Ensure Your Financial Security with Loan Aligned with
                    <span className="fw-bold"> your requirements.</span>
                  </p>
                </div>

                <div className="container">
                  <form action="">
                    <div className="row mt-4 ps-md-5">
                      <div className="col-lg-6 ">
                        <div className="text-start">
                          <label htmlFor="" className="fw-bold mb-3">
                            Loan Type
                          </label>
                          <select
                            name=""
                            id=""
                            className="form-select"
                            value={loanType}
                            onChange={handleLoanTypeChange}
                          >
                            <option value="">Select Loan Type</option>
                            <option value="Personal Loan">Personal Loan</option>
                            <option value="Car Loan">Car Loan</option>
                            <option value="Home Loan">Home Loan</option>
                            <option value="Business Loan">Business Loan</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    {showAdditionalFields && (
                      <>
                        <div className="row mt-4 ps-md-5">
                          <div className="col-lg-6 text-start">
                            <div>
                              <label htmlFor="" className="mb-3 fw-bold">
                                First Name
                              </label>
                            </div>
                            <div>
                              <input
                                type="text"
                                className="form-control p-3"
                                placeholder="Enter Your First Name"
                                name="FirstName"
                                value={FirstName}
                                onChange={(e) => SetFirstName(e.target.value)}
                                required
                                onKeyPress={handleAlphaOnly}
                              />
                            </div>
                          </div>

                          <div className=" col-lg-6   text-start">
                            <div>
                              <label htmlFor="" className="mb-3 fw-bold">
                                Last Name
                              </label>
                            </div>
                            <div>
                              <input
                                type="text"
                                className="form-control p-3"
                                placeholder="Enter Your Last Name"
                                name="LastName"
                                value={LastName}
                                onChange={(e) => SetLastName(e.target.value)}
                                required
                                onKeyPress={handleAlphaOnly}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-4 ps-md-5">
                          <div className="col-lg-6 ">
                            <div className="text-start">
                              <label htmlFor="" className="fw-bold mb-3">
                                Email Address
                              </label>
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control p-3"
                                  placeholder="Enter Your Email Address"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  name="EmailAddress"
                                  value={EmailAddress}
                                  onChange={(e) =>
                                    SetEmailAddress(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 ">
                            <div className="text-start">
                              <label htmlFor="" className="fw-bold mb-3">
                                Contact Number
                              </label>
                              <div className="input-group mb-3">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  +91
                                </span>
                                <input
                                  type="text"
                                  className="form-control p-3"
                                  placeholder="Enter Your Contact Number"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  maxLength={"10"}
                                  name="ContactNum"
                                  value={ContactNum}
                                  onChange={(e) => {
                                    SetContactNum(e.target.value);
                                    handleContactcod(e);
                                  }}
                                  onKeyPress={handleContactcod}
                                />
                              </div>
                              {ContactError && (
                                <span style={{ color: "red" }}>
                                  {ContactError}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* <div className="row mt-4 ps-md-5">
                          <div className="col-lg-6 ">
                            <div className="text-start">
                              <label htmlFor="" className="fw-bold mb-3">
                                Occupation Type
                              </label>
                              <div className="input-group mb-3">
                                <select
                                  name=""
                                  id=""
                                  className="form-select p-3"
                                >
                                  <option value="">Select Occupation</option>
                                  <option value="">Salarid</option>
                                  <option value="">Self Employeed</option>
                                  <option value="">Other</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 ">
                            <div className="text-start">
                              <label htmlFor="" className="fw-bold mb-3">
                                Income
                              </label>
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control p-3"
                                  placeholder="Enter Income"
                                  aria-label="Income"
                                  aria-describedby="basic-addon1"
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}

                        {loanType === "Car Loan" && (
                          <>
                            <div
                              className="row mt-5 mb-3 ps-md-5 needs-validation"
                              novalidate
                            >
                              <div className=" col-lg-6  text-start">
                                <div>
                                  <label htmlFor="" className="mb-3 fw-bold">
                                    Do You Have Any Vehicle
                                  </label>
                                </div>
                              </div>

                              <div className=" col-lg-6   text-start">
                                <div>
                                  <input
                                    type="radio"
                                    id=""
                                    name=""
                                    value=""
                                    className=""
                                    required
                                    checked={
                                      selectYes === "Yes" ? "true" : null
                                    }
                                    onClick={() => handleRadioClick("Yes")}
                                  />
                                  <label for="" className="me-5 ps-3">
                                    Yes
                                  </label>

                                  <input
                                    type="radio"
                                    id=""
                                    name=""
                                    value=""
                                    checked={selectYes === "No" ? "true" : null}
                                    onClick={() => handleRadioClick("No")}
                                    required
                                  />
                                  <label for="" className="ps-3">
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4 ps-md-5">
                              <div className="col-lg-6 ">
                                <div className="text-start">
                                  <label htmlFor="" className="fw-bold mb-3">
                                    Vehicle Type
                                  </label>
                                  <div className=" mb-3">
                                    <select
                                      className="form-select p-3"
                                      name="Vehicle_Type"
                                      value={Carloandata.Vehicle_Type}
                                      onChange={(e) => {
                                        addValues2(e);
                                      }}
                                      required
                                      disabled={selectYes === "No"}
                                    >
                                      <option value="">
                                        Select Vehicle Type
                                      </option>
                                      <option value="Two Wheeler">
                                        Two Wheeler
                                      </option>
                                      <option value="Four Wheeler">
                                        Four Wheeler
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 ">
                                <div className="text-start">
                                  <label htmlFor="" className="fw-bold mb-3">
                                    Registration Number
                                  </label>
                                  <div className="input-group mb-3">
                                    <input
                                      type="text"
                                      className="form-control p-3"
                                      placeholder="Enter Registration Number"
                                      name="Registration_Number"
                                      value={Carloandata.Registration_Number}
                                      onChange={(e) => {
                                        addValues2(e);
                                      }}
                                      required
                                      disabled={selectYes === "No"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="row mt-4 ps-md-5">
                              <div className="col-lg-6 ">
                                <div className="text-start">
                                  <label htmlFor="" className="fw-bold mb-3">
                                    Occupation Type
                                  </label>
                                  <div className="input-group mb-3">
                                    <select
                                      name=""
                                      id=""
                                      className="form-select p-3"
                                    >
                                      <option value="">
                                        Select Occupation
                                      </option>
                                      <option value="">Salarid</option>
                                      <option value="">Self Employeed</option>
                                      <option value="">Other</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 ">
                                <div className="text-start">
                                  <label htmlFor="" className="fw-bold mb-3">
                                    Income
                                  </label>
                                  <div className="input-group mb-3">
                                    <input
                                      type="text"
                                      className="form-control p-3"
                                      placeholder="Enter Income"
                                      aria-label="Income"
                                      aria-describedby="basic-addon1"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </>
                        )}

                        {loanType === "Personal Loan" && (
                          <>
                            <div className="row mt-4 ps-md-5">
                              <div className="col-lg-6 ">
                                <div className="text-start">
                                  <label htmlFor="" className="fw-bold mb-3">
                                    Occupation Type
                                  </label>
                                  <div className="input-group mb-3">
                                    <select
                                      className="form-select p-3"
                                      name="Occupation_Type"
                                      value={Personalloandata.Occupation_Type}
                                      onChange={(e) => {
                                        addValues(e);
                                      }}
                                    >
                                      <option value="">
                                        Select Occupation
                                      </option>
                                      <option value="Salaried">Salaried</option>
                                      <option value="Self Employeed">
                                        Self Employeed
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 ">
                                <div className="text-start">
                                  <label htmlFor="" className="fw-bold mb-3">
                                    Monthly Income
                                  </label>
                                  <div className="input-group mb-3">
                                    <input
                                      type="text"
                                      className="form-control p-3"
                                      placeholder="Enter Monthly Income"
                                      aria-label="Income"
                                      aria-describedby="basic-addon1"
                                      name="Income"
                                      value={Personalloandata.Income}
                                      onChange={(e) => {
                                        addValues(e);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="row mt-4 ps-md-5">
                          <div className="col-md-12 ">
                            <div className="text-start">
                              <label htmlFor="" className="fw-bold mb-3">
                                Pincode
                              </label>
                              <input
                                type="text"
                                className="form-control p-3"
                                placeholder="Enter Pincode"
                                required
                                maxLength={"6"}
                                onKeyPress={handleNumeric}
                                name="Pinncode"
                                value={Pinncode}
                                onChange={(e) => {
                                  SetPinncode(e.target.value);
                                  handleNumeric(e);
                                }}
                              />
                              {pincodError && (
                                <span style={{ color: "red" }}>
                                  {pincodError}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <button
                          className="p-2 mt-5 but23 w-25 fs-5"
                          onClick={(e) => {
                            handlesubmit1(e, loanType); // Assuming loanType is defined somewhere
                            handleNextButtonClick(e,emailIsValid)
                          }}
                        >
                          Check Offer
                        </button>
                      </>
                    )}
                  </form>
                </div>
              </div>

              <div className="col-lg-4  d-none d-lg-block">
                <div className="position-relative mt-5 py-5 ">
                  <img src={loan} alt="" className="" />
                </div>
              </div>
            </div>
          </div>

          {/* HPI */}
          <div className="container-fluid">
            <div className="row  mx-5">
              <div className="col-md-12   text-start p-3">
                <h2> Our Loan Services :</h2>
              </div>
              <div className="row ">
                <div className="col-lg-12 ">
                  <div className="text-start ">
                    <p className="fs-4">
                      Welcome to our Loan section! Unlock your financial
                      potential with our tailored loan solutions designed to
                      meet your unique needs. Whether you're dreaming of a new
                      home, planning a major purchase, or consolidating debt, we
                      offer flexible loan options with competitive rates and
                      terms. Our streamlined application process ensures quick
                      approvals and hassle-free transactions, empowering you to
                      achieve your goals with confidence.
                    </p>
                  </div>

                  <div className="text-start">
                    <p className="fs-4">
                      Benefit from personalized guidance from our team of
                      experts and enjoy the convenience of managing your loan
                      online. Take control of your financial future and explore
                      our range of loan products today.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 
      <div className="container-fluid">
        <div className="row px-5 mb-5">
          <div className="col-md-12">
            <h5 style={{ textAlign: "justify" }} className="fs-1 ">
              FAQ
            </h5>
          </div>
        </div> */}
          {/* <div className="row px-5 ">
          <div className="col-lg-8 ">
            <div className="row ">
              <div className="col-md-12">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Why do I need insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How is insurance premium calculated?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What is a waiting period in insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How do I file an insurance claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I customize my insurance coverage?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>

          <div className="col-4  d-none d-lg-block">
            <div>
              <img src={question} alt="" />
            </div>
          </div>
        </div>
      </div> */}

          {/* Footer */}

          <footer>
            <div className="container-fluid background-imag1 mt-5 ">
              <div className="row p-5">
                <div className="col-sm-6 order-2 order-sm-1 text-start">
                  <div className="row ps-5">
                    <div className="col-lg-6 ">
                      <ul className="list-unstyled text-start list">
                        <li className="mb-3 fw-bold fs-5">Home </li>
                        <li className="mb-3 fw-bold fs-5">PD Advantages</li>
                        <li className="mb-3 fw-bold fs-5">Customer Rating</li>
                        <li className="mb-3 fw-bold fs-5">About Us</li>
                      </ul>
                    </div>

                    <div className="col-lg-6">
                      <ul className="list-unstyled text-start list">
                        <li className="mb-3 fw-bold fs-5">Support</li>
                        <li className="mb-3 fw-bold fs-5">Claim</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 p-5 text-sm-end text-center order-1 ">
                  <img
                    src={log}
                    alt=""
                    style={{ width: "5vw" }}
                    className="logo"
                  />
                </div>
              </div>
            </div>
          </footer>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h5 className="fs-5">
                  &#169;2020 Policy Dekhoo | Powered by Policy Dekhoo | Designed
                  And Developed By{" "}
                  <a
                    href="https://www.unairesync.com/"
                    className="link"
                    target="blank"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Unaire Sync
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default LoanForm1;
