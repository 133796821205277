import React from "react";
import { useState } from "react";
// import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import logo from "../resources/Logo.svg";
// import { Accordion } from 'react-bootstrap';
import health from "../resources/healthsv.svg";
import "./form1health.css";
// import healthques from "../resources/heathquestion.svg"
import Female1 from "../resources/Fem.svg";
import Female2 from "../resources/Fem-modified.png";
import Male1 from "../resources/male.svg";
import Male2 from "../resources/male-modified.png";
import Daughter1 from "../resources/Girl.svg";
import Daughter2 from "../resources/Girl-modified.png";
import Son1 from "../resources/Boy.svg";
import Son2 from "../resources/Boy-modified.png";
import Mother1 from "../resources/ma.svg";
import Mother2 from "../resources/ma-modified.png";
import Father1 from "../resources/Pa.svg";
import Father2 from "../resources/Pa-modified.png";
import Healthform2 from "./Healthform2";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";
import { useNavigate } from "react-router-dom";

function HealthForm1() {
  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  const [quantity, setQuantity] = useState(0);
  const [showQuantityButtons, setShowQuantityButtons] = useState(false);
  const [daughterquantity, setdaughterQuantity] = useState(0);
  const [showdaughterButtons, setShowdaughterButtons] = useState(false);

  // const increaseQuantity = () => {
  //   if (quantity < 2) {
  //     setQuantity(quantity + 1);
  //   }
  // };

  // const decreaseQuantity = () => {
  //   if (quantity > 0) {
  //     setQuantity(quantity - 1);
  //   }
  // };

  // const increasQuantity = () => {
  //   if (daughterquantity < 2) {
  //     setdaughterQuantity(daughterquantity + 1);
  //   }
  // };

  // const decreasQuantity = () => {
  //   if (daughterquantity > 0) {
  //     setdaughterQuantity(daughterquantity - 1);
  //   }
  // };

  const [currentImage, setCurrentImage] = useState(Female2);
  const [changesImage, setchnagesImage] = useState(Male2);
  const [changesImag, setchnagesImag] = useState(Daughter2);
  const [changesImg, setchnagesImg] = useState(Son2);
  const [changesGrandmother, setchnagesGrandmother] = useState(Mother2);
  const [changesGrandfather, setchnagesGrandfather] = useState(Father2);
  const [familyArr, setFamilyArr] = useState([]);
  const [Healthformm2, sethealthform2] = useState(false);

  const [gender, setGender] = useState("male");
  console.log(gender);
  const proceedform = () => {
    if (pincode !== "") {
      console.log("ooooooooooo");

      sethealthform2(true);
    }
  };

  const handleFemaleClick = () => {
    setCurrentImage(currentImage === Female1 ? Female2 : Female1);
    let inplist = [...familyArr];
    if (familyArr.includes("Female") === false) {
      if (currentImage === Female2) {
        inplist.push("Spouse");
        setFamilyArr(inplist);
        setHealthInsuranceData({ ...HealthInsuranceData, Spouse: "Female" });
      }
    } else {
      inplist = inplist.filter((item) => item !== "Spouse");
      setFamilyArr(inplist);
      setHealthInsuranceData({ ...HealthInsuranceData, Spouse: "" });
    }
  };
  // console.log(familyArr);

  const handleMaleClick = () => {
    setchnagesImage(changesImage === Male1 ? Male2 : Male1);
    console.log(familyArr.includes("Self"), "check it is in the list or not");
    let inplist = [...familyArr];
    if (familyArr.includes("Male") === false) {
      if (changesImage === Male2) {
        inplist.push("Self");
        setFamilyArr(inplist);
        setHealthInsuranceData({ ...HealthInsuranceData, Self_Person: "Male" });
      }
    } else {
      inplist = inplist.filter((item) => item !== "Self");
      setFamilyArr(inplist);
      setHealthInsuranceData({ ...HealthInsuranceData, Self_Person: "" });
    }
  };
  console.log(familyArr, "familyyyy array");

  const daughterimagechangeClick = () => {
    setchnagesImag(changesImag === Daughter1 ? Daughter2 : Daughter1);
    setShowdaughterButtons(
      (prevshowdaughterButtons) => !prevshowdaughterButtons
    );
  };
  // const daughterClick = () => {

  //   let inplist = [...familyArr];
  //   if (familyArr.includes("Daughter") === false) {
  //     if (changesImag === Daughter2) {
  //       inplist.push("Daughter");
  //       setFamilyArr(inplist);
  //     }
  //   } else {
  //     inplist = inplist.filter((item) => item !== "Daughter");
  //     setFamilyArr(inplist);
  //   }
  // };

  const decreasQuantity = () => {
    if (daughterquantity > 0) {
      setdaughterQuantity(daughterquantity - 1);
      let inplist = [...familyArr];
      const index = inplist.lastIndexOf("Daughter");
      if (index !== -1) {
        inplist.splice(index, 1);
        setFamilyArr(inplist);
        setHealthInsuranceData({
          ...HealthInsuranceData,
          Daughter: "Daughter",
        });
      }
    }
  };
  const increasQuantity = () => {
    if (daughterquantity < 4) {
      setdaughterQuantity(daughterquantity + 1);
      let inplist = [...familyArr];
      inplist.push("Daughter");
      setFamilyArr(inplist);
      setHealthInsuranceData({ ...HealthInsuranceData, Daughter: "Daughter" });
    }
  };

  // console.log(familyArr);

  const boyimagechangeClick = () => {
    setchnagesImg(changesImg === Son1 ? Son2 : Son1);
    setShowQuantityButtons(
      (prevShowQuantityButtons) => !prevShowQuantityButtons
    );
  };
  // const boyClick = () => {
  //   // setchnagesImg(changesImg === Son1 ? Son2 : Son1);
  //   let inplist = [...familyArr];
  //     if (changesImg === Son2) {
  //       inplist.push("Son");
  //       setFamilyArr(inplist);
  //     }

  // };
  const decreaseQuantity = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
      let inplist = [...familyArr];
      const index = inplist.lastIndexOf("Son");
      if (index !== -1) {
        inplist.splice(index, 1);
        setFamilyArr(inplist);
        setHealthInsuranceData({ ...HealthInsuranceData, Son: "Son" });
      }
    }
  };
  const increaseQuantity = () => {
    if (quantity < 4) {
      setQuantity(quantity + 1);
      let inplist = [...familyArr];
      inplist.push("Son");
      setFamilyArr(inplist);
      setHealthInsuranceData({ ...HealthInsuranceData, Son: "Son" });
    }
  };
  // console.log(familyArr);
  const GrandmotherClick = () => {
    setchnagesGrandmother(changesGrandmother === Mother1 ? Mother2 : Mother1);
    let inplist = [...familyArr];
    if (familyArr.includes("Mother") === false) {
      if (changesGrandmother === Mother2) {
        inplist.push("Mother");
        setFamilyArr(inplist);
        setHealthInsuranceData({ ...HealthInsuranceData, Mother: "Mother" });
      }
    } else {
      inplist = inplist.filter((item) => item !== "Mother");
      setFamilyArr(inplist);
      setHealthInsuranceData({ ...HealthInsuranceData, Mother: "Mother" });
    }
  };
  // console.log(familyArr);
  const GrandfatherClick = () => {
    setchnagesGrandfather(changesGrandfather === Father1 ? Father2 : Father1);
    let inplist = [...familyArr];
    if (familyArr.includes("Father") === false) {
      if (changesGrandfather === Father2) {
        inplist.push("Father");
        setFamilyArr(inplist);
        setHealthInsuranceData({ ...HealthInsuranceData, Father: "Father" });
      }
    } else {
      inplist = inplist.filter((item) => item !== "Father");
      setFamilyArr(inplist);
      setHealthInsuranceData({ ...HealthInsuranceData, Father: "Father" });
    }
  };
  // console.log(familyArr);

  const [toogle, settoggle] = useState(false);
  const [pincode, setPincode] = useState("");
  console.log(pincode, "-=-=-=-==");
  const handleNumericOnly = (event) => {
    const { value } = event.target;

    const isnot = /^[0-9]*$/.test(event.key);
    const isnot2 = /^$/.test(event.key);
    if (isnot || isnot2) {
      setPincode(value);
    } else {
      event.preventDefault();
    }
  };
  console.log(pincode);

  // const [pincod, setPincod] = useState("");
  const [pincodError, setPincodError] = useState("");

  const handleNumeric = (event) => {
    const { value } = event.target;
    const isValid = /^[0-9]*$/.test(event.key) || /^$/.test(event.key);

    if (isValid) {
      setPincode(value);
      setPincodError(""); // Reset error message
    } else {
      event.preventDefault();
      setPincodError("Pincode should contain only numbers.");
    }

    if (value.length !== 6) {
      setPincodError("Pincode should be 6 digits long.");
    } else {
      setPincodError("");
    }
  };

  const [HealthInsuranceData, setHealthInsuranceData] = useState({
    Self_Gender: "",
    Self_Person: "",
    Spouse: "",
    Daughter: "",
    Son: "",
    Mother: "",
    Father: "",
  });
  const addValues = (e) => {
    setHealthInsuranceData({
      ...HealthInsuranceData,
      [e.target.name]: e.target.value,
    });
  };
  console.log(familyArr,"[][][][[][[][[][][][][][][][]");
  return (
    <>
      {Healthformm2 === true ? (
        <Healthform2 familyArr={familyArr} />
      ) : (
        <>
          <div>
            {toogle === false ? (
              <>
                <div className="container-fluid mb-5 ">
                  <div className="row ">
                    <div
                      className="col-lg-4 mt-3 col-10 ps-5   text-start"
                      onClick={() => {
                        navigateTo("/");
                      }}
                    >
                      <img
                        src={logo}
                        alt=""
                        className=""
                        style={{ width: "4vw", cursor: "pointer" }}
                      />
                      <span
                        className="fs-3 p-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigateTo("/");
                        }}
                      >
                        Policy Dekhoo
                      </span>
                    </div>

                    <div className="col-lg-8 col-2 pe-5  d-flex justify-content-end ">
                      <div className="row mt-3 ">
                        <div className="col-12  d-none d-lg-block">
                          <ul className="list-inline">
                            <li
                              className="list-inline-item p-2  fs-6"
                              style={{ cursor: "pointer" }}
                            >
                              Home
                            </li>
                            <li
                              className="list-inline-item p-2 fs-6"
                              style={{ cursor: "pointer" }}
                            >
                              PD Advantages
                            </li>
                            <li
                              className="list-inline-item p-2 fs-6"
                              style={{ cursor: "pointer" }}
                            >
                              Customer Rating
                            </li>
                            <li
                              className="list-inline-item p-2 fs-6"
                              style={{ cursor: "pointer" }}
                            >
                              About Us
                            </li>
                            <li
                              className="list-inline-item p-2 fs-6"
                              style={{ cursor: "pointer" }}
                            >
                              Support
                            </li>
                            <li
                              className="list-inline-item p-2 fs-6"
                              style={{ cursor: "pointer" }}
                            >
                              Claim
                            </li>
                            <li
                              className="list-inline-item get fs-6 p-2"
                              style={{ cursor: "pointer" }}
                            >
                              Contact Us
                            </li>
                          </ul>
                        </div>
                        <div className="col d-lg-none">
                          <img
                            src={hambergar}
                            alt=""
                            onClick={(e) => settoggle(!toogle)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {toogle === true ? (
              <>
                <div className="container">
                  <div className="row mt-5">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-8  text-start ps-5">
                          <span className="fs-3">Policy dekhoo</span>
                        </div>
                        <div className="col-4">
                          <img
                            src={cross}
                            alt=""
                            onClick={(e) => settoggle(!toogle)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-7 col-1">
                      <ul
                        className=""
                        style={{ listStyle: "none", textAlign: "left" }}
                      >
                        <li
                          className=" p-2  fs-6"
                          style={{ cursor: "pointer" }}
                        >
                          Home
                        </li>
                        <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                          PD Advantages
                        </li>
                        <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                          Customer Rating
                        </li>
                        <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                          About Us
                        </li>
                        <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                          Support
                        </li>
                        <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                          Claim
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12">
                      <span className="get p-2">Contact Us</span>
                    </div>
                  </div>

                  <div className="row fixed-bottom">
                    <div className="col-12">
                      <div className=" ">
                        <span>
                          © 2020 Policy Dekhoo | Powered by Policy Dekhoo
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {toogle === false ? (
              <>
                <div className="container-fluid">
                  <div className="row ">
                    <div className="col-lg-10  ps-5">
                      <div className="text-start  ">
                        <span
                          onClick={() => {
                            navigateTo("/");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Home&#62;
                        </span>
                        <span className="fw-bold">Health Insurace</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-7  ps-5">
                      <div className="row">
                        <div className="col-md-6 col-4 ">
                          <hr
                            className="w-100"
                            style={{
                              height: "2px",
                              // width: "10vw",
                              color: "#2D75E0",
                              border: "",
                            }}
                          />
                        </div>
                        <div className="col-md-6 col-4 ">
                          <hr style={{ height: "2px" }} className="w-100" />
                        </div>
                      </div>
                      <div className="heade">
                        <div className="">
                          <p className="fs-2" style={{ textAlign: "left" }}>
                            Find the Health Insurance Plan That Matches{" "}
                            <span className="fw-bold">your requirements.</span>
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row mb-4">
                            <div className="col-sm-6 col-6 text-end">
                              <span
                                onClick={() => {
                                  setGender("male");
                                }}
                                style={{ cursor: "pointer" }}
                                className="fw-bold fs-5"
                              >
                                Male
                              </span>
                            </div>
                            <div className="col-md-6 col-6 text-start">
                              <span
                                onClick={() => {
                                  setGender("female");
                                }}
                                style={{ cursor: "pointer" }}
                                className="fw-bold fs-5"
                              >
                                Female
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-lg-4 mt-5 col-6">
                          <div className="">
                            <button
                              className="w-75 self"
                              style={{ border: "none" }}
                              onClick={
                                gender === "male"
                                  ? handleMaleClick
                                  : handleFemaleClick
                              }
                            >
                              <img
                                src={
                                  gender === "male"
                                    ? changesImage
                                    : currentImage
                                }
                                alt=""
                                className=""
                              />
                              self
                            </button>
                          </div>
                        </div>

                        <div className="col-lg-4 mt-5 col-6">
                          <div className="">
                            <button
                              className="w-75 self"
                              style={{ border: "none" }}
                              onClick={
                                gender === "male"
                                  ? handleFemaleClick
                                  : handleMaleClick
                              }
                            >
                              <img
                                src={
                                  gender === "male"
                                    ? currentImage
                                    : changesImage
                                }
                                alt=""
                                className=""
                              />
                              Spouse
                            </button>
                          </div>
                        </div>

                        <div className="col-lg-4 mt-5 col-6">
                          <div className="">
                            <button
                              className="w-75 self"
                              style={{ border: "none" }}
                              onClick={daughterimagechangeClick}
                            >
                              <img src={changesImag} alt="" className="" />
                              Daughter
                            </button>
                            {showdaughterButtons && (
                              <div>
                                <label
                                  className="minus p-2"
                                  onClick={decreasQuantity}
                                >
                                  -
                                </label>
                                <span>{daughterquantity}</span>
                                <label
                                  className="minus p-2"
                                  onClick={increasQuantity}
                                >
                                  +
                                </label>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4 col-6 mt-5">
                          <div className="">
                            <button
                              className="w-75 self"
                              style={{ border: "none" }}
                              onClick={boyimagechangeClick}
                            >
                              <img src={changesImg} alt="" className="" />
                              Son
                            </button>
                            {showQuantityButtons && (
                              <div>
                                <label
                                  className="minus p-2"
                                  onClick={decreaseQuantity}
                                >
                                  -
                                </label>
                                <span>{quantity}</span>
                                <label
                                  className="minus p-2"
                                  onClick={increaseQuantity}
                                >
                                  +
                                </label>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4 col-6 mt-5">
                          <div>
                            <button
                              className="w-75 self"
                              style={{ border: "none" }}
                              onClick={GrandmotherClick}
                            >
                              <img
                                src={changesGrandmother}
                                alt=""
                                className=""
                              />
                              Mother
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-4 col-6 mt-5">
                          <div>
                            <button
                              className="w-75 self"
                              style={{ border: "none" }}
                              onClick={GrandfatherClick}
                            >
                              <img
                                src={changesGrandfather}
                                alt=""
                                className=""
                              />
                              Father
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-5">
                        <div className="col-lg-8">
                          <div className="text-start ">
                            <label htmlFor="" className="mb-4 fw-bold ps-3">
                              Pincode
                            </label>
                            <div>
                              <input
                                type="text"
                                className="form-control p-3"
                                // value={pincode}
                                id=""
                                placeholder="Enter Pin code"
                                required
                                maxLength={"6"}
                                onKeyPress={handleNumeric}
                                onChange={(e) => {
                                  handleNumeric(e)
                                }}
                              />
                              {pincodError && (
                                <span style={{ color: "red" }}>
                                  {pincodError}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 mt-5">
                          <button
                            onClick={() => {
                              proceedform();
                            }}
                            className="but30 w-50 p-3"
                          >
                            Proceed
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 ps-5 d-none d-lg-block">
                      <div className="">
                        <img src={health} alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container-fluid mt-5 py-5">
                  <div className="row mx-5">
                    <div className="col-lg-12 text-start ">
                      <h2>Health Insurance Plan</h2>
                    </div>
                    <div className="row mt-5">
                      <div className="col-lg-12 ">
                        <div className="text-start ">
                          <p className="fs-4">
                            Put your health first with Policy Dekhoo's health
                            insurance plans, meticulously designed to cater to
                            your well-being needs. Our comprehensive coverage
                            not only includes hospitalisation expenses but also
                            extends to pre and post-hospitalization costs,
                            ambulance charges, and more. Whether you opt for
                            individual or family floater plans, rest assured of
                            lifelong renewability and access to cashless
                            hospitalisation facilities.
                          </p>
                        </div>

                        <div className="text-start ">
                          <p className="fs-4">
                            Benefit from our extensive network of hospitals and
                            personalised assistance, ensuring prompt and
                            effective medical care whenever the need arises.
                            With Policy dekhoo, your health is our priority,
                            offering peace of mind knowing that you're covered
                            with the best possible care.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='container-fluid'>

            <div className='row px-5 mb-5 ms-3'>
                        <div className='col-sm-12'>
                            <h5 style={{textAlign:"justify"}} className='fs-1 '>FAQ</h5>
                        </div>
                    </div>
           <div className='row px-5 ms-3'>
                        <div className='col-sm-8 '>
                            <div className='row '>
                                <div className='col-sm-12'>
                                <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Why do I need insurance?</Accordion.Header>
                                    <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>How is insurance premium calculated?</Accordion.Header>
                                    <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>What is a waiting period in insurance?</Accordion.Header>
                                    <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>How do I file an insurance claim?</Accordion.Header>
                                    <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Can I customize my insurance coverage?</Accordion.Header>
                                    <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>
                                </div>
                            </div>
                        </div>


                        <div className='col-sm-4 '>
                            <div>
                                <img src={healthques} alt="" />
                            </div>
                        </div>
                    </div>
                </div> */}

                <footer>
                  <div className="container-fluid background-imag1 mt-5 ">
                    <div className="row p-5">
                      <div className="col-sm-6 order-2 order-sm-1 text-start">
                        <div className="row ps-5">
                          <div className="col-lg-6 ">
                            <ul className="list-unstyled text-start list">
                              <li className="mb-3 fw-bold fs-5">Home </li>
                              <li className="mb-3 fw-bold fs-5">
                                PD Advantages
                              </li>
                              <li className="mb-3 fw-bold fs-5">
                                Customer Rating
                              </li>
                              <li className="mb-3 fw-bold fs-5">About Us</li>
                            </ul>
                          </div>

                          <div className="col-lg-6">
                            <ul className="list-unstyled text-start list">
                              <li className="mb-3 fw-bold fs-5">Support</li>
                              <li className="mb-3 fw-bold fs-5">Claim</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6 col-md-6 p-5 text-sm-end text-center order-1 ">
                        <img
                          src={logo}
                          alt=""
                          style={{ width: "5vw" }}
                          className="logo"
                        />
                      </div>
                    </div>
                  </div>
                </footer>

                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <h5 className="fs-6">
                        &#169;2020 Policy Dekhoo | Developed By{" "}
                        <a
                          href="https://www.unairesync.com/"
                          className="link"
                          target="blank"
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          Unaire Sync
                        </a>
                      </h5>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </>
  );
}

export default HealthForm1;
