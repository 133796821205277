import React from "react";
// import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
// import { Accordion } from "react-bootstrap";
import "./Group.css";
import { useState, useRef } from "react";
import groupsv from "../resources/groupin.svg";
// import groupque from "../resources/groupqu.svg";
import logo from "../resources/Logo.svg";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";
import { useNavigate } from "react-router-dom";

function GroupInsuranceform() {
  const [toogle, settoggle] = useState(false);

  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };


  const contactFormRef = useRef(null);

  // Function to scroll to the contact form
  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNextButtonClick = (e) => {
    e.preventDefault();
    setinvaildemail(false)
    const emailIsValid = mailvalid2(GroupInsuranceData.Company_Email);
    if(emailIsValid){
      const form = document.querySelector("form");
      if (form.checkValidity()) {
        // Proceed to the next page
        navigateTo("/GroupInsuranceform/ThankYou");
      } else {
        // Show validation errors
        form.reportValidity();
      }
    }else{
      console.log("Email or name is invalid");
      setinvaildemail(true)
    }
  };

  const [invaildemail, setinvaildemail] = useState(false)

  const mailvalid2=(variablename)=>{
    const checkmail= /^([A-Za-z0-9._]+@[A-Za-z]+\.[A-Za-z]{2,4})$/
    if(variablename.match(checkmail)){
        //email validate true
        console.log("helloooo");
        return(true)
    }
    else{
        //email invalid
        console.log("invald");
        return(false)
    }

  }


  // const [toogle, settoggle] = useState(false);
  const [Pincode, setPincode] = useState("");
  console.log(Pincode, "-=-=-=-==");
  const handleNumericOnly = (event) => {
    const { value } = event.target;

    const isnot = /^[0-9]*$/.test(event.key);
    const isnot2 = /^$/.test(event.key);
    if (isnot || isnot2) {
      setPincode(value);
    } else {
      event.preventDefault();
    }
  };

  const [pincod, setPincod] = useState("");
  const [pincodError, setPincodError] = useState("");

  const handleNumeric = (event) => {
    const { value } = event.target;
    const isValid = /^[0-9]*$/.test(event.key) || /^$/.test(event.key);

    if (isValid) {
      setPincod(value);
      setPincodError(""); // Reset error message
    } else {
      event.preventDefault();
      setPincodError("Pincode should contain only numbers.");
    }

    if (value.length !== 6) {
      setPincodError("Pincode should be 6 digits long.");
    } else {
      setPincodError("");
    }
  };

  const [Contactcod, setContactcod] = useState("");
  const [ContactError, setContactError] = useState("");

  const handleContactcod = (event) => {
    const { value } = event.target;
    const isValid = /^[0-9]*$/.test(event.key);

    if (isValid) {
      setContactcod(value);
      setContactError(""); // Reset error message
    } else {
      event.preventDefault();
      setContactError("Contact should contain only numbers.");
    }

    if (value.length !== 10) {
      setContactError("Contact number should be 10 digits long.");
    } else {
      setContactError("");
    }
  };

  const [GroupInsuranceData, setGroupInsuranceData] = useState({
    Group_Type: "",
    Company_Name: "",
    Pincode: "",
    Company_Email: "",
    Number_Of_Employee: "",
    Contact_Number: "",
  });

  const addValues = (e) => {
    if(e.target.name==="Email_Address"){
      setinvaildemail(false)
    }
    setGroupInsuranceData({
      ...GroupInsuranceData,
      [e.target.name]: e.target.value,
    });
  };

  const handlesubmit = async (e) => {
    // e.preventDefault();
    console.log(GroupInsuranceData);
    try {
      const response = await fetch(
        "https://policydekhoo-backend.onrender.com/Setgroupinsurancedata",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(GroupInsuranceData),
        }
      );

      if (response) {
        console.log("Data added successfully");
      } else {
        console.error("Failed to add data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  console.log(GroupInsuranceData);

  const [name, setname] = useState("")
  const handleAlphaOnly = (event) => {
    const { value } = event.target;

    const isnot = /^[a-zA-Z]*$/.test(event.key);
    const isnot2 = /^$/.test(event.key);
    if (isnot || isnot2) {
      setname(value);
    } else {
      event.preventDefault();
    }
  };

 
  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid mb-5 ">
            <div className="row ">
              <div
                className="col-lg-4 mt-3 col-10 ps-5   text-start"
                onClick={() => {
                  navigateTo("/");
                }}
              >
                <img
                  src={logo}
                  alt=""
                  className=""
                  style={{ width: "4vw", cursor: "pointer" }}
                />
                <span
                  className="fs-3 p-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigateTo("/");
                  }}
                >
                  Policy Dekhoo
                </span>
              </div>

              <div className="col-lg-8 col-2 pe-5  d-flex justify-content-end ">
                <div className="row mt-3 ">
                  <div className="col-12  d-none d-lg-block">
                    <ul className="list-inline">
                      <li
                        className="list-inline-item p-2  fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Home
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        About Us
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Support
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Claim
                      </li>
                      <li
                        className="list-inline-item get fs-6 p-2"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Contact Us
                      </li>
                    </ul>
                  </div>
                  <div className="col d-lg-none">
                    <img
                      src={hambergar}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8  text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7 col-1">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li className=" p-2  fs-6" style={{ cursor: "pointer" }}>
                    Home
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    PD Advantages
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Customer Rating
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    About Us
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }} onClick={scrollToContactForm}>
                    Support
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Claim
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2" onClick={scrollToContactForm}>Contact Us</span>
              </div>
            </div>

            <div className="row fixed-bottom">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid">
            <div className="row ">
              <div className="col-md-10 ps-5 mt-4">
                <div className="text-start  mx-5">
                  <span
                    className="fw-lighter"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigateTo("/");
                    }}
                  >
                    Home&#62;
                  </span>
                  <span style={{ fontWeight: "400" }}>Group Insurance</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 d-none d-lg-block">
                <div className="">
                  <img src={groupsv} alt="" />
                </div>
              </div>
              <div className="col-lg-5 ps-5">
                <div className="heade">
                  <div className=" ">
                    <p className="fs-2" style={{ textAlign: "left" }}>
                      Customised Insurance Solutions for Your Corporate{" "}
                      <span className="fw-bold">your requirements.</span>
                    </p>
                  </div>
                </div>
                <form action="">
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <div className="text-start">
                        <label htmlFor="" className="fw-bold mb-3">
                          Group Type
                        </label>
                        <div>
                          <select
                            className="form-select p-3"
                            id=""
                            name="Group_Type"
                            value={GroupInsuranceData.Group_Type}
                            onChange={(e) => {
                              addValues(e);
                            }}
                          >
                            <option value="">Select Group Type</option>
                            <option value="Group Health Insurance">
                              Group Health Insurance
                            </option>
                            <option value="Group Term Life">
                              Group Term Life
                            </option>
                            <option value="Group Personal Accident">
                              Group Personal Accident
                            </option>
                            <option value="Other Insurance">
                              Other Insurance
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="text-start">
                        <label htmlFor="" className="fw-bold mb-3">
                          Company Name
                        </label>
                        <input
                          type="text"
                          className="form-control p-3"
                          id=""
                          placeholder="Enter Company Name"
                          required
                          value={GroupInsuranceData.Company_Name}
                          onKeyPress={handleAlphaOnly}
                          name="Company_Name"
                          onChange={(e) => {
                            addValues(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-6">
                      <div className="text-start">
                        <label htmlFor="" className="fw-bold mb-3">
                          Pincode
                        </label>
                        <input
                          type="text"
                          className="form-control p-3"
                          id=""
                          placeholder="Enter Pincode"
                          required
                          maxLength={"6"}
                          value={GroupInsuranceData.Pincode}
                          onKeyPress={handleNumeric}
                          name="Pincode"
                          onChange={(e) => {
                            addValues(e);
                            handleNumeric(e)
                          }}
                        />
                        {pincodError && (
                          <span style={{ color: "red" }}>{pincodError}</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="text-start">
                        <label htmlFor="" className="fw-bold mb-3">
                          Company Email
                        </label>
                        <input
                          type="text"
                          className="form-control p-3"
                          id=""
                          placeholder="Enter Comapny Email Address"
                          required
                          value={GroupInsuranceData.Company_Email}
                          name="Company_Email"
                          onChange={(e) => {
                            addValues(e);
                          }}
                          style={{border:invaildemail===true?"2px solid red":"" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4 mb-5 ">
                    <div className="col-md-6">
                      <div className="text-start">
                        <label htmlFor="" className="fw-bold mb-3">
                          Number Of Employees
                        </label>
                        <input
                          type="text"
                          class="form-control p-3"
                          placeholder="Number Of Employees"
                          required
                          value={GroupInsuranceData.Number_Of_Employee}
                          name="Number_Of_Employee"
                          onChange={(e) => {
                            addValues(e);
                            handleNumericOnly(e)
                          }}
                          onKeyPress={handleNumericOnly}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-start">
                        <label htmlFor="" className="fw-bold mb-3">
                          Contact Number
                        </label>
                        <div className="input-group flex-nowrap">
                          <span class="input-group-text" id="addon-wrapping">
                            +91
                          </span>
                          <input
                            type="text"
                            class="form-control p-3"
                            placeholder="Enter Your Contact Number"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            maxLength={"10"}
                            onKeyPress={handleContactcod}
                            value={GroupInsuranceData.Contact_Number}
                            name="Contact_Number"
                            onChange={(e) => {
                              addValues(e);
                              handleContactcod(e)
                            }}
                          />
                        </div>
                        {ContactError && (
                          <span style={{ color: "red" }}>{ContactError}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      className="p-2 fs-5 but6 w-25"
                      onClick={(e) => {
                        handleNextButtonClick(e)
                        handlesubmit(e);
                      }}
                    >
                      Check Premium
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row  mx-5">
              <div className="col-lg-12 text-start p-4">
                <h2>Group Insurance Plan</h2>
              </div>
              <div className="row ">
                <div className="col-md-12">
                  <div className="text-start">
                    <p className="fs-4">
                      Shield your employees and fortify your business with
                      Policy dekhoo's group/corporate insurance solutions. Our
                      comprehensive coverage includes health insurance, group
                      term life insurance, and more, tailored to the unique
                      needs and budget of your organization.
                    </p>
                  </div>

                  <div className="text-start ">
                    <p className="fs-4">
                      With Policy Dekhoo, gain peace of mind knowing that your
                      employees are protected by trusted insurance partners. Our
                      dedicated account management and expedited claims
                      processing exemplify our commitment to being your reliable
                      insurance ally. Count on us to safeguard your workforce
                      and business interests with steadfast dedication.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container-fluid">
        <div className="row px-5 mb-5 ps-3">
          <div className="col-lg-12">
            <h5 style={{ textAlign: "justify" }} className="fs-1 ">
              FAQ
            </h5>
          </div>
        </div>
        <div className="row px-5 ps-3">
          <div className="col-lg-8 ">
            <div className="row ">
              <div className="col-md-12">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Why do I need insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How is insurance premium calculated?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What is a waiting period in insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How do I file an insurance claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I customize my insurance coverage?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>

          <div className="col-lg-4 d-none d-lg-block">
            <div>
              <img src={groupque} alt="" />
            </div>
          </div>
        </div>
      </div> */}

          <footer>
            <div className="container-fluid background-imag1 mt-5 ">
              <div className="row p-5">
                <div className="col-sm-6 order-2 order-sm-1 text-start">
                  <div className="row ps-5">
                    <div className="col-lg-6 ">
                      <ul className="list-unstyled text-start list">
                        <li className="mb-3 fw-bold fs-5">Home </li>
                        <li className="mb-3 fw-bold fs-5">PD Advantages</li>
                        <li className="mb-3 fw-bold fs-5">Customer Rating</li>
                        <li className="mb-3 fw-bold fs-5">About Us</li>
                      </ul>
                    </div>

                    <div className="col-lg-6">
                      <ul className="list-unstyled text-start list">
                        <li className="mb-3 fw-bold fs-5">Support</li>
                        <li className="mb-3 fw-bold fs-5">Claim</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 p-5 text-sm-end text-center order-1 ">
                  <img
                    src={logo}
                    alt=""
                    style={{ width: "5vw" }}
                    className="logo"
                  />
                </div>
              </div>
            </div>
          </footer>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h5 className="fs-6">
                  &#169;2020 Policy Dekhoo | Developed By{" "}
                  <a
                    href="https://www.unairesync.com/"
                    className="link"
                    target="blank"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Unaire Sync
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default GroupInsuranceform;
